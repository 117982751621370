import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';
import { DateTime } from 'luxon';

import { PageWrapper } from 'views/common/StyledComponents';
import TasksColumn from './TasksColumn';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { taskSelectors, taskThunks } from 'store/ducks/task';
import { ErrorBoundary } from '@sentry/react';
import Error from 'views/common/Error';
import HeaderList from 'views/common/HeaderList';
import Loader from 'views/common/Loader';
import { Stack, useMediaQuery } from '@mui/material';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';
import HeaderPageMobile from 'views/common/HeaderPageMobile';

const TasksGridView = ({
  pageView,
  togglePageView,
  filter,
  createNewItemAction,
  handleFilterChange,
  hasPermission,
}) => {
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const tasks = useSelector(taskSelectors.getActiveTasks());
  const tasksLoading = useSelector(taskSelectors.activeTasksLoading());

  const filterTasksByType = filter.type ? tasks.filter((task) => task.type?.id === filter?.type?.id) : tasks;

  const query = searchParams.toString();

  const today = DateTime.local().startOf('day');
  const toDoTodayList = filterTasksByType
    .filter((task) => {
      const dueDateTime = task.dueTime
        ? DateTime.fromISO(task.dueDate, { zone: task.timeZone }).set({
            hour: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).hour,
            minute: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).minute,
          })
        : DateTime.fromISO(task.dueDate, { zone: task.timeZone }).endOf('day');

      const nowInTaskTimeZone = DateTime.now().setZone(task.timeZone);
      return DateTime.fromISO(task.dueDate).equals(today) && dueDateTime >= nowInTaskTimeZone;
    })
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity)
      );
    });

  const toDoTomorrowList = filterTasksByType
    .filter((task) => DateTime.fromISO(task.dueDate).equals(today.plus({ days: 1 })))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const overdueList = filterTasksByType.filter((task) => {
    const dueDateTime = task.dueTime
      ? DateTime.fromISO(task.dueDate, { zone: task.timeZone }).set({
          hour: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).hour,
          minute: DateTime.fromISO(task.dueTime, { zone: task.timeZone }).minute,
        })
      : DateTime.fromISO(task.dueDate, { zone: task.timeZone });

    // Convert the current time to the task's time zone
    const nowInTaskTimeZone = DateTime.now().setZone(task.timeZone);

    return (task.dueTime ? dueDateTime < nowInTaskTimeZone : dueDateTime < today) || !task.dueDate;
  });

  const sortedOverdueList = overdueList.sort((a, b) => {
    const dateA = a.dueTime
      ? DateTime.fromISO(a.dueDate, { zone: a.timeZone }).set({
          hour: DateTime.fromISO(a.dueTime, { zone: a.timeZone }).hour,
          minute: DateTime.fromISO(a.dueTime, { zone: a.timeZone }).minute,
        })
      : DateTime.fromISO(a.dueDate, { zone: a.timeZone });

    const dateB = b.dueTime
      ? DateTime.fromISO(b.dueDate, { zone: b.timeZone }).set({
          hour: DateTime.fromISO(b.dueTime, { zone: b.timeZone }).hour,
          minute: DateTime.fromISO(b.dueTime, { zone: b.timeZone }).minute,
        })
      : DateTime.fromISO(b.dueDate, { zone: b.timeZone });

    return dateA.valueOf() - dateB.valueOf();
  });

  const toDoFutureList = filterTasksByType
    .filter((task) => DateTime.fromISO(task.dueDate) > today.plus({ days: 1 }))
    .sort((a, b) => {
      return (
        (a.dueDate ? DateTime.fromISO(a.dueDate).valueOf() : Infinity) -
        (b.dueDate ? DateTime.fromISO(b.dueDate).valueOf() : Infinity)
      );
    });

  const TASKS_COLUMNS = [
    { title: t('types.task.overdue'), tasksList: sortedOverdueList },
    { title: t('types.task.today'), tasksList: toDoTodayList },
    { title: t('types.task.tomorrow'), tasksList: toDoTomorrowList },
    { title: t('types.task.future'), tasksList: toDoFutureList },
  ];

  const scrollColumnContainerRef = useRef(null);
  const [currentColumnIndex, setCurrentColumnIndex] = useState(0);

  const handleScroll = () => {
    if (!scrollColumnContainerRef.current) return;

    const scrollLeft = scrollColumnContainerRef.current.scrollLeft;
    const columnWidth = scrollColumnContainerRef.current.scrollWidth / TASKS_COLUMNS.length;

    const newIndex = Math.round(scrollLeft / columnWidth);

    if (newIndex !== currentColumnIndex) {
      setCurrentColumnIndex(newIndex);
    }
  };

  useEffect(() => {
    const scrollContainer = scrollColumnContainerRef.current;
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll);
      }
    };
  }, [TASKS_COLUMNS]);

  useEffect(() => {
    let params = {};

    if (filter?.byMe) {
      params.byMe = true;
    }
    if (filter) {
      params.my = filter.my;
    }
    if (filter) {
      params.notMy = filter.notMy;
    }

    setSearchParams(params);
  }, [setSearchParams, filter]);

  useEffect(() => {
    query && store.dispatch(taskThunks.fetchActiveTasks(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      {isDesktop ? (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <HeaderList
            title={t('types.task.namePlural')}
            pageView={pageView}
            togglePageView={togglePageView}
            buttonTitle={'+ ' + t('base.buttons.createTask')}
            to="/tasks/new"
            filter={filter}
            handleFilter={handleFilterChange}
            hasPermission={hasPermission}
          />
        </ErrorBoundary>
      ) : (
        <HeaderPageMobile
          pageView={pageView}
          togglePageView={togglePageView}
          createNewItemAction={createNewItemAction}
          tasksFilter={filter}
          handleFilterChange={handleFilterChange}
          columnIndicator
          currentColumnIndex={currentColumnIndex}
          indicatorColumnsList={TASKS_COLUMNS}
          hasPermission={hasPermission}
        />
      )}
      {tasksLoading ? (
        <Loader />
      ) : (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <Stack
            ref={scrollColumnContainerRef}
            direction="row"
            height="100%"
            gap={2}
            width="100%"
            sx={{
              height: { sm: 'calc(100% - 118px)', md: 'calc(100% - 100px)', lg: 'calc(100% - 76px)' },
              ...(isMobile && {
                overflowX: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }),
            }}
          >
            {TASKS_COLUMNS.map((column) => (
              <TasksColumn key={column.title} column={column} list={column.tasksList} />
            ))}
          </Stack>
        </ErrorBoundary>
      )}
    </PageWrapper>
  );
};

export default memo(TasksGridView);
