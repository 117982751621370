import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, useTheme } from '@mui/material';

import { DataTableWrapper } from './StyledComponents';
import DataTable from './DataTable';
import CopyInputAdornment from 'views/form/CopyInputAdornment';

import filters from 'filters';

const SessionsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  trainerQuery,
  periodQuery,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/sessions/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: 'lesson.program.name',
      headerName: t('base.labels.program'),
      filterable: false,
      renderCell: ({ row: { lesson } }) => {
        return <Typography>{lesson?.program?.name}</Typography>;
      },
      minWidth: 160,
      flex: 0.4,
    },
    {
      field: 'group',
      headerName: t('base.labels.group'),
      filterable: false,
      renderCell: ({ row: { group } }) => {
        return <Typography>{group?.name}</Typography>;
      },
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: 'lesson.name',
      headerName: t('base.labels.sessionTopic'),
      filterable: false,
      renderCell: ({ row: { lesson } }) => {
        return <Typography>{lesson?.name}</Typography>;
      },
      minWidth: 160,
      flex: 0.4,
    },
    {
      field: 'date',
      headerName: t('base.labels.date'),
      filterable: false,
      renderCell: ({ row: { date } }) => {
        const shortDate = filters.date(date);
        return <Typography>{shortDate}</Typography>;
      },
      minWidth: 120,
      flex: 0.3,
    },
    { field: 'duration', headerName: t('base.labels.duration'), filterable: false, minWidth: 80, flex: 0.3 },
    {
      field: 'record',
      headerName: t('base.labels.recordLink'),
      filterable: false,
      minWidth: 160,
      flex: 0.4,
      renderCell: ({ row: { record } }) => {
        return <Typography color="info.main"> {record}</Typography>;
      },
    },
    {
      field: 'actions',
      type: 'actions',
      filterable: false,
      minWidth: 120,
      flex: 0.3,
      renderCell: (params) => {
        return <CopyInputAdornment value={params.row.record} tooltipTitle={t('base.tooltips.copyRecordLink')} />;
      },
    },
  ];
  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
        trainerQuery={trainerQuery}
        periodQuery={periodQuery}
      />
    </DataTableWrapper>
  );
};

export default memo(SessionsList);
