import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import InvoicesList from 'views/common/InvoicesList';

const PersonInvoices = ({ personId }) => {
  const invoices = useSelector(personSelectors.getPersonInvoices());
  const invoicesLoading = useSelector(personSelectors.personInvoicesLoading());

  const invoicePermission = hasPermission(PERMISSIONS.invoice.read);

  useEffect(() => {
    if (personId) {
      invoicePermission && store.dispatch(personThunks.fetchPersonInvoices(personId));
    }
  }, [personId, invoicePermission]);

  useEffect(() => {
    return () => store.dispatch(personActions.clearItemWithInitialState({ collection: 'userInvoices', data: [] }));
  }, []);

  return (
    <InvoicesList
      list={invoices}
      totalElements={invoices.length}
      isLoading={invoicesLoading}
      columnVisibilityModel={{
        contractor: false,
      }}
      hideFooter
      isNested
    />
  );
};

export default memo(PersonInvoices);
