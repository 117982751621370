import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, useMediaQuery } from '@mui/material';

import { FormColumnTitle, FormColumnWrapper } from 'views/common/StyledComponents';
import FormikTextField from 'views/form/FormikTextField';

import { MOBILE_VIEW } from 'utils/constants/constants';
import FormikArrayTextField from 'views/form/FormikArrayTextField';

const AssignmentFormFields = ({ values }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={isMobile ? 1 : 2}
      width="100%"
      sx={{ ...(isMobile && { overflowY: 'auto' }) }}
    >
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '50%' }}>
        <FormColumnTitle>{t('types.assignment.info')}</FormColumnTitle>
        <FormikTextField
          name="status"
          label={t('base.labels.status')}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikTextField
          name="number"
          label={t('base.labels.number')}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikTextField
          name="name"
          label={t('base.labels.name')}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikTextField
          name="type"
          label={t('base.labels.type')}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikTextField
          name="requirement"
          label={t('base.labels.requirement')}
          InputProps={{
            readOnly: true,
          }}
        />
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '50%' }}>
        <FormColumnTitle>{t('types.assignment.details')}</FormColumnTitle>
        <FormikTextField
          name="program"
          label={t('base.labels.program')}
          InputProps={{
            readOnly: true,
          }}
        />

        <FormikTextField
          name="module"
          label={t('base.labels.module')}
          InputProps={{
            readOnly: true,
          }}
        />

        <FormikTextField
          name="lesson"
          label={t('base.labels.lesson')}
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikTextField
          name="deadlineDays"
          label={t('base.labels.deadlineDays')}
          placeholder="Read only"
          InputProps={{
            readOnly: true,
          }}
        />
        <FormikArrayTextField
          name="documents"
          values={values.documents}
          label={t('base.labels.documents')}
          placeholder="Read only"
          copyInputAdornment
          InputProps={{
            readOnly: true,
          }}
        />
      </FormColumnWrapper>
    </Stack>
  );
};

export default memo(AssignmentFormFields);
