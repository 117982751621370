import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import store from 'store';
import { invoiceSelectors, invoiceThunks } from 'store/ducks/invoice';

import HeaderList from 'views/common/HeaderList';
import InvoicesList from 'views/common/InvoicesList';
import Error from 'views/common/Error';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { PageWrapper } from 'views/common/StyledComponents';
import { DESKTOP_VIEW } from 'utils/constants/constants';
import { useMediaQuery } from '@mui/material';
import HeaderPageMobile from 'views/common/HeaderPageMobile';

const InvoicesPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const invoices = useSelector(invoiceSelectors.getInvoices());
  const invoicesLoading = useSelector(invoiceSelectors.invoicesLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(invoiceThunks.fetchInvoices(`?${query}`));
  }, [query]);

  const navigateToCreateNewInvoicePage = () => {
    navigate('/invoices/new');
  };

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        {isDesktop ? (
          <HeaderList
            title={t('types.invoice.namePlural')}
            createNewItemAction={navigateToCreateNewInvoicePage}
            buttonTitle={'+ ' + t('base.buttons.createInvoice')}
            isSearchbarShow
            hasPermission={hasPermission(PERMISSIONS.invoice.create)}
          />
        ) : (
          <HeaderPageMobile
            isSearchbarShow
            createNewItemAction={navigateToCreateNewInvoicePage}
            hasPermission={hasPermission(PERMISSIONS.invoice.create)}
          />
        )}
      </ErrorBoundary>
      <InvoicesList
        list={invoices.content}
        isLoading={invoicesLoading}
        totalElements={invoices.totalElements}
        totalPages={invoices.totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        columnVisibilityModel={{ comment: false }}
      />
    </PageWrapper>
  );
};

export default memo(InvoicesPage);
