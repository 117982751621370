import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Typography, useTheme } from '@mui/material';

import DataTable from 'views/common/DataTable';
import { DataTableWrapper } from 'views/common/StyledComponents';

import { STATUSES_COLOR } from 'utils/constants/constants';

const LessonsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/lessons/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    { field: 'number', headerName: t('base.labels.number'), filterable: false, minWidth: 80, flex: 0.2 },
    {
      field: 'type',
      headerName: t('base.labels.type'),
      filterable: false,
      renderCell: ({ row: { type } }) => {
        return <Typography>{type?.label}</Typography>;
      },
      minWidth: 100,
      flex: 0.3,
    },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, minWidth: 240, flex: 0.6 },
    {
      field: 'plannedDuration',
      headerName: t('base.labels.plannedDuration'),
      filterable: false,
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: 'program',
      headerName: t('base.labels.program'),
      filterable: false,
      minWidth: 160,
      flex: 0.4,
      renderCell: ({ row: { program } }) => {
        return <Typography>{program.name}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
    },
  ];
  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)', overflowY: 'auto' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
      />
    </DataTableWrapper>
  );
};

export default memo(LessonsList);
