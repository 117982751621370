import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import DataTable from 'views/common/DataTable';
import { DataTableWrapper } from 'views/common/StyledComponents';

const AssignmentGradeCriteria = ({
  list,
  totalElements,
  isLoading,
  hideFooter,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  isNested,
}) => {
  const { t } = useTranslation();
  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
    },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, minWidth: 160, flex: 0.4 },
    { field: 'weight', headerName: t('base.labels.weight'), filterable: false, minWidth: 80, flex: 0.3 },
    { field: 'description', headerName: t('base.labels.description'), filterable: false, minWidth: 200, flex: 0.5 },
  ];
  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
      />
    </DataTableWrapper>
  );
};

export default memo(AssignmentGradeCriteria);
