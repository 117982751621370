import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import GradesList from 'views/common/GradesList';

const PersonGrades = ({ personId }) => {
  const grades = useSelector(personSelectors.getPersonGrades());
  const gradesLoading = useSelector(personSelectors.personGradesLoading());

  const gradePermission = hasPermission(PERMISSIONS.grade.read);

  useEffect(() => {
    if (personId) {
      gradePermission && store.dispatch(personThunks.fetchPersonGrades(personId));
    }
  }, [personId, gradePermission]);

  useEffect(() => {
    return () => store.dispatch(personActions.clearItemWithInitialState({ collection: 'userGrades', data: [] }));
  }, []);

  return (
    <GradesList
      list={grades}
      isLoading={gradesLoading}
      totalElements={grades.length}
      columnVisibilityModel={{
        student: false,
      }}
      hideFooter
      isNested
    />
  );
};

export default memo(PersonGrades);
