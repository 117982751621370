import { memo } from 'react';
import { Stack, Typography, useMediaQuery } from '@mui/material';
import { MOBILE_VIEW } from 'utils/constants/constants';

const Dashboard = () => {
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Stack sx={{ width: '100%', px: { xs: 2 } }} justifyContent="center" alignItems="center">
      <Typography
        color="warning.main"
        sx={{ mb: 2, fontSize: { sm: 42, xs: 30 }, fontWeight: 700, order: 1, textAlign: 'center' }}
      >
        Dashboard is Coming soon...
      </Typography>
      <Typography
        variant="h1"
        color="text.dark"
        sx={{
          mb: isMobile ? 0 : 5,
          marginTop: isMobile ? 5 : 0,
          order: isMobile ? 3 : 2,
          fontSize: { sm: 30, xs: 24 },
          textAlign: 'center',
        }}
      >
        But for now, please choose a report
      </Typography>
      <img
        src="/construction.png"
        alt="under construction"
        style={{ width: '260px', height: 'auto', order: isMobile ? 2 : 3 }}
      />
    </Stack>
  );
};

export default memo(Dashboard);
