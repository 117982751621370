import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from 'store';
import { lessonActions, lessonSelectors, lessonThunks } from 'store/ducks/lesson';

import AssignmentsList from 'views/common/AssignmentsList';

import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';

const SessionAssignments = ({ session }) => {
  const assignments = useSelector(lessonSelectors.getLessonAssignments());
  const assignmentsLoading = useSelector(lessonSelectors.lessonAssignmentsLoading());

  useEffect(() => {
    session?.lesson &&
      hasPermission(PERMISSIONS.assignment.read) &&
      store.dispatch(lessonThunks.fetchLessonAssignments(session.lesson?.id));
  }, [session?.lesson]);

  useEffect(() => {
    return () => store.dispatch(lessonActions.clearItemWithInitialState({ collection: 'lessonAssignments', data: [] }));
  }, []);

  return (
    <AssignmentsList
      list={assignments}
      totalElements={assignments.length}
      columnVisibilityModel={{
        contractor: false,
      }}
      isLoading={assignmentsLoading}
      hideFooter
      isNested
    />
  );
};

export default memo(SessionAssignments);
