import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, useMediaQuery } from '@mui/material';

import Modal from 'views/common/modal/Modal';
import ProgramModulesForm from './ProgramModulesForm';

import useModal from 'hooks/useModal';
import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';
import { MOBILE_VIEW } from 'utils/constants/constants';

const CreateNewModule = ({ programId }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [modalOpen, openModal, closeModal] = useModal();
  return (
    hasPermission(PERMISSIONS.contract.create) && (
      <>
        <Button
          variant={isMobile ? 'text' : 'contained'}
          onClick={openModal}
          sx={{ minWidth: { xs: 0 }, fontWeight: { sm: 400, xs: 700 } }}
        >
          {isMobile ? t('base.buttons.createModuleMob') : '+' + t('base.buttons.createModule')}
        </Button>

        <Modal title={t('base.buttons.createModule')} questionIcon open={modalOpen} onClose={closeModal}>
          <ProgramModulesForm closeModal={closeModal} programId={programId} t={t} />
        </Modal>
      </>
    )
  );
};

export default memo(CreateNewModule);
