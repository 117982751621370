import { memo, useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import store from 'store';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';
import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';
import { contractSelectors, contractThunks } from 'store/ducks/contract';

import HeaderCard from 'views/common/HeaderCard';
import { PageWrapper } from '../../../common/StyledComponents';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';

import { hasPermission } from 'utils/roles';
import { PERMISSIONS } from 'utils/constants/permissions';
import { validationContractForm } from '../../../../utils/schema';
import { uploadFilesAndReturnUploadedFiles } from 'utils/helpers';
import { getFullName, getProgramHours, getShortName } from 'utils/dataTable';
import InvoicesList from 'views/common/InvoicesList';
import TasksList from 'views/pages/tasks/components/TasksList';
import ContractFormFields from './ContractFormFields';

const ContractForm = ({
  contract,
  doc,
  modalOpen,
  openModal,
  closeModal,
  isSubmitting,
  values,
  files,
  setFiles,
  setFieldValue,
  dirty,
}) => {
  const { t } = useTranslation();
  const [contractView, setContractView] = useState('data');

  const invoices = useSelector(contractSelectors.getContractInvoices());
  const invoicesLoading = useSelector(contractSelectors.contractInvoicesLoading());

  const tasks = useSelector(contractSelectors.getContractTasks());
  const tasksLoading = useSelector(contractSelectors.contractTasksLoading());

  const clients = useSelector(personSelectors.getClients());
  const clientsLoading = useSelector(personSelectors.clientsLoading());
  const managers = useSelector(dictionarySelectors.getManagers());
  const legalEntities = useSelector(dictionarySelectors.getLegalEntities());
  const enums = useSelector(dictionarySelectors.getEnums());
  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading());
  const groups = useSelector(groupSelectors.getGroups());
  const groupsLoading = useSelector(groupSelectors.groupsLoading());

  const permission = contract ? hasPermission(PERMISSIONS.contract.update) : hasPermission(PERMISSIONS.contract.create);
  /** --- Default contractor value --- */
  const [defaultValue, setDefaultValue] = useState(null);

  /** --- Create the full name in order to compare with searchValue in Autocomplete search and avoid unnecessary fetch --- */
  const valueSearchStudent = values.student ? getFullName(values.student) : '';
  const valueSearchContractor = values.contractor ? getFullName(values.contractor) : '';

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setStudentSearchValue] = useAutocompleteSearch(contract, valueSearchStudent, personThunks.fetchClients);
  const [setContractorSearchValue] = useAutocompleteSearch(contract, valueSearchContractor, personThunks.fetchClients);
  const [setProgramSearchValue] = useAutocompleteSearch(contract, values?.program?.name, programThunks.fetchPrograms);
  const [setGroupSearchValue] = useAutocompleteSearch(contract, values?.group?.name, groupThunks.fetchGroups);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.student, personSelectors.getClients(), personActions.setClients);
  useAddEntityIfNotExists(values?.contractor, personSelectors.getClients(), personActions.setClients);
  useAddEntityIfNotExists(values?.program, programSelectors.getPrograms(), programActions.setPrograms);
  useAddEntityIfNotExists(values?.group, groupSelectors.getGroups(), groupActions.setGroups);

  const getGroupCoordinator = () => {
    const coordinator = values?.group?.coordinator || '';
    return getShortName(coordinator);
  };

  /** --- Set the contractor value when we set or change student --- */
  useEffect(() => {
    if (!contract && values.student) {
      setDefaultValue(values.student);
    }
  }, [contract, values.student]);

  useEffect(() => {
    if (!values.program) {
      setFieldValue('modules', []);
    }
  }, [values.program, setFieldValue]);

  useEffect(() => {
    contract && store.dispatch(contractThunks.fetchContractTasks(contract.id));
  }, [contract]);

  useEffect(() => {
    hasPermission(PERMISSIONS.invoice.read) &&
      contract &&
      store.dispatch(contractThunks.fetchContractInvoices(contract.id));
  }, [contract]);

  const handleContractViewChange = (event, newView) => {
    if (contractView === newView || newView === null) return;

    setContractView(newView);
  };

  const sortedByDueDateList = invoices?.slice().sort((a, b) => {
    const dateA = new Date(a.dueDate);
    const dateB = new Date(b.dueDate);
    return dateA - dateB;
  });

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="contractForm" style={{ height: '100%' }}>
          {/* --- HEADER OF FORM WITH BUTTONS --- */}
          {/* --- passing props in Header component for opening popUp and render buttons depending on new card or current one --- */}
          <HeaderCard
            title={contract ? t('types.contract.name') : t('types.contract.new')}
            data={contract}
            isSubmitting={isSubmitting}
            formId="contractForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            doc={doc}
            isFormChanged={dirty}
          />
          <Stack height="calc(100% - 60px)" gap={1} mt={{ lg: 1 }} sx={{ overflowY: 'auto' }}>
            {contract && (
              <ToggleButtonGroup
                color="primary"
                value={contractView}
                exclusive
                onChange={handleContractViewChange}
                sx={{
                  paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
                  width: '100%',
                }}
              >
                <ToggleButton sx={{ width: '100%' }} value="data">
                  {t('base.dictionary.data')}
                </ToggleButton>
                <ToggleButton
                  sx={{ width: '100%' }}
                  value="invoices"
                  disabled={!hasPermission(PERMISSIONS.invoice.read)}
                >
                  {t('types.invoice.namePlural')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="tasks">
                  {t('types.task.namePlural')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {contractView === 'data' && (
              <ContractFormFields
                values={values}
                clients={clients}
                clientsLoading={clientsLoading}
                managers={managers}
                legalEntities={legalEntities}
                enums={enums}
                programs={programs}
                programsLoading={programsLoading}
                groups={groups}
                groupsLoading={groupsLoading}
                setStudentSearchValue={setStudentSearchValue}
                setContractorSearchValue={setContractorSearchValue}
                setProgramSearchValue={setProgramSearchValue}
                setGroupSearchValue={setGroupSearchValue}
                defaultValue={defaultValue}
                files={files}
                setFiles={setFiles}
                getGroupCoordinator={getGroupCoordinator}
                getProgramHours={getProgramHours}
                isSubmitting={isSubmitting}
                contract={contract}
              />
            )}

            {contractView === 'invoices' && (
              <InvoicesList
                list={sortedByDueDateList}
                totalElements={invoices.length}
                columnVisibilityModel={{
                  contractor: false,
                }}
                isLoading={invoicesLoading}
                hideFooter
                isNested
              />
            )}
            {contractView === 'tasks' && (
              <TasksList isNested contractTasks={tasks} contractTasksLoading={tasksLoading} />
            )}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};
export default memo(
  withFormik({
    mapPropsToValues: ({ contract = {} }) => ({
      ...contract,
      id: contract?.id,
      student: contract?.student || null,
      contractor: contract?.contractor || null,
      number: contract?.number || '',
      responsible: contract?.responsible || null,
      legalEntity: contract?.legalEntity || null,
      documents: contract?.documents || [],
      status: contract?.status || null,
      paymentMethod: contract?.paymentMethod || null,
      amount: contract?.amount || '',
      date: contract?.date || null,
      program: contract?.program || null,
      group: contract?.group || null,
      comment: contract?.comment || '',
      modules: contract?.modules || [],
      files: contract?.files || [],
    }),
    validationSchema: validationContractForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      /** -- upload files and waiting response with uploaded files -- */
      const uploadedFiles = await uploadFilesAndReturnUploadedFiles(props.files, values);
      /**  -- update values with uploaded files data ---- */
      const updatedValues = { ...values, files: uploadedFiles.filter((file) => file !== '') }; // remove empty items from array

      const response = props.contract
        ? await store.dispatch(contractThunks.updateContract(updatedValues))
        : await store.dispatch(contractThunks.createContract(updatedValues));

      if (!response.error) {
        toast.success(
          props.contract
            ? props.t('messages.success.toast.updateContract')
            : props.t('messages.success.toast.createContract')
        );
        setSubmitting(false);

        if (props.contract) {
          props.closeModal(),
            response.meta.requestStatus === 'fulfilled' &&
              (await store.dispatch(contractThunks.fetchContractInvoices(values.id)));
        } else props.navigate(`/contracts/${response.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(ContractForm)
);
