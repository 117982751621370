import { Box, Button, Divider, Menu, MenuItem, Stack } from '@mui/material';
import { forwardRef, memo, useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { hasPermission } from 'utils/roles';
import { Link, useLocation } from 'react-router-dom';
import { REPORTS_MENU } from 'utils/constants/menuReports';
import { useTranslation } from 'react-i18next';

const ReportsMenu = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState({ groupIndex: 0, itemIndex: 0 });
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  useEffect(() => {
    for (let groupIndex = 0; groupIndex < REPORTS_MENU.length; groupIndex++) {
      const group = REPORTS_MENU[groupIndex];
      for (let itemIndex = 0; itemIndex < group.length; itemIndex++) {
        const menuItem = group[itemIndex];
        if (location.pathname.includes(menuItem.to)) {
          setSelectedIndex({ groupIndex, itemIndex });
          return;
        }
      }
    }
  }, [location.pathname]);

  const CustomLink = forwardRef((props, ref) => {
    return <Link ref={ref} {...props} />;
  });
  CustomLink.displayName = 'CustomLink';

  const handleListItemClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemClick = (e, groupIndex, itemIndex) => {
    setSelectedIndex({ groupIndex, itemIndex });
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItems = REPORTS_MENU.map((group, groupIndex) => {
    const groupWithPermission = group.filter((menuItem) => hasPermission(menuItem.permission));
    const hasItemsWithPermission = groupWithPermission.length > 0;

    return (
      hasItemsWithPermission && (
        <Box key={groupIndex}>
          {group.map((menuItem, itemIndex) => (
            <MenuItem
              key={menuItem.key}
              sx={{ color: 'text.main' }}
              component={CustomLink}
              to={menuItem.to}
              selected={groupIndex === selectedIndex.groupIndex && itemIndex === selectedIndex.itemIndex}
              onClick={(event) => handleMenuItemClick(event, groupIndex, itemIndex)}
            >
              <Stack direction="row" sx={{ gap: 2 }}>
                {menuItem.icon}
                {t(`reportsMenu.${menuItem.key}`)}
              </Stack>
            </MenuItem>
          ))}
          {groupIndex < REPORTS_MENU.length - 1 && <Divider />}
        </Box>
      )
    );
  });

  return (
    <>
      <Button
        onClick={handleListItemClick}
        sx={{
          minWidth: { sm: 170, xs: 0 },
          color: 'text.main',
          fontSize: { sm: 20, xs: 16 },
          fontWeight: 700,
          py: 0,
          textAlign: 'left',
          '&:hover': { background: 'none', color: 'primary.main' },
        }}
        variant="text"
        endIcon={
          open ? (
            <KeyboardArrowUpIcon fontSize="large" sx={{ color: 'primary.main' }} />
          ) : (
            <KeyboardArrowDownIcon fontSize="large" sx={{ color: 'primary.main' }} />
          )
        }
      >
        {t(`reportsMenu.${REPORTS_MENU[selectedIndex.groupIndex][selectedIndex.itemIndex].key}`)}
      </Button>

      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'settingsMenu-button',
          role: 'listbox',
        }}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default memo(ReportsMenu);
