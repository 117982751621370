import { memo, useEffect, useState } from 'react';
import { FieldArray, Form, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Stack, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, PageWrapper } from 'views/common/StyledComponents';

import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { getFullName, getNameAndNumber, getShortName } from 'utils/dataTable';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';

const GradeForm = ({ grade, values, setFieldValue }) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [gradeView, setGradeView] = useState('data');

  const clients = useSelector(personSelectors.getClients());
  const clientsLoading = useSelector(personSelectors.clientsLoading());
  const studentGroups = useSelector(personSelectors.getPersonGroups());
  const groupsLoading = useSelector(personSelectors.personGroupsLoading());
  const mentors = useSelector(dictionarySelectors.getMentors());
  const mentorsLoading = useSelector(dictionarySelectors.mentorsLoading());
  const assignments = useSelector(programSelectors.getProgramAssignments());
  const assignmentsLoading = useSelector(programSelectors.programAssignmentsLoading());

  /** --- Mapping student groups in order to exclude extra data and keep only groups --- */
  const groups = studentGroups
    ? studentGroups.map(({ group }) => {
        return group;
      })
    : [];

  /** --- Create the full name in order to compare with searchValue in Autocomplete search and avoid unnecessary fetch --- */
  const valueSearchStudent = values.student ? getFullName(values.student) : '';

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setStudentSearchValue] = useAutocompleteSearch(grade, valueSearchStudent, personThunks.fetchClients);

  /** --- Custom hook for handling data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.student, personSelectors.getClients(), personActions.setClients);

  /** --- Getting person groups --- */
  useEffect(() => {
    values?.student?.id && store.dispatch(personThunks.fetchPersonGroups(values?.student?.id));

    /** --- Clear store with person groups --- */
    return () => store.dispatch(personActions.clearItemWithInitialState({ collection: 'userGroups', data: [] }));
  }, [values.student]);

  /** - Getting program assignments --- */
  useEffect(() => {
    values?.group?.program?.id && store.dispatch(programThunks.fetchProgramAssignments(values?.group?.program?.id));

    /** --- Clear store with program assignments --- */
    return () =>
      store.dispatch(programActions.clearItemWithInitialState({ collection: 'programAssignments', data: [] }));
  }, [values.group]);

  const handleGradeViewChange = (event, newView) => {
    if (gradeView === newView || newView === null) return;

    setGradeView(newView);
  };

  return (
    <PageWrapper>
      <Form id="gradeForm" style={{ height: '100%' }}>
        <HeaderCard title={t('types.grade.name')} />
        <Stack height="calc(100% - 76px)" gap={1} sx={{ overflowY: 'auto' }} mt={{ lg: 1 }}>
          {grade && values.criteria.length > 0 && (
            <ToggleButtonGroup
              color="primary"
              value={gradeView}
              exclusive
              onChange={handleGradeViewChange}
              sx={{
                paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
                width: '100%',
              }}
            >
              <ToggleButton sx={{ width: '100%' }} value="data">
                {t('base.dictionary.data')}
              </ToggleButton>
              <ToggleButton
                sx={{ width: '100%' }}
                value="criteria"
                // disabled={!hasPermission(PERMISSIONS.assignment.read)}
              >
                {t('types.assignment.gradeCriteria')}
              </ToggleButton>
            </ToggleButtonGroup>
          )}
          {gradeView === 'data' && (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              gap={isDesktop ? 2 : 1}
              width="100%"
              sx={{ ...(isMobile && { overflowY: 'auto' }) }}
            >
              <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
                <FormColumnTitle>{t('types.grade.info')}</FormColumnTitle>
                <FormikAutocomplete
                  name="student"
                  label={t('base.labels.student')}
                  onInputChange={(e, value, reason) => {
                    setStudentSearchValue(value);
                    if (reason === 'clear') {
                      setFieldValue('group', null);
                    }
                  }}
                  getCustomLabel={getFullName}
                  options={clients.content}
                  loading={clientsLoading}
                  forReadOnly
                />
                <FormikAutocomplete
                  name="assignment"
                  label={t('base.labels.assignment')}
                  getCustomLabel={getNameAndNumber}
                  options={assignments}
                  loading={assignmentsLoading}
                  forReadOnly
                />
                <TextField
                  name="type"
                  label={t('base.labels.assignmentType')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={values?.assignment?.type?.label || ''}
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
                <FormColumnTitle>{t('types.grade.contractInfo')}</FormColumnTitle>
                <FormikAutocomplete
                  name="group"
                  label={t('base.labels.group')}
                  options={groups}
                  loading={groupsLoading}
                  forReadOnly
                />
                <TextField
                  name="program"
                  label={t('base.labels.program')}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    readOnly: true,
                  }}
                  value={values?.group?.program?.name || ''}
                />
                <FormikAutocomplete
                  name="mentors"
                  label={t('base.labels.mentor')}
                  getCustomLabel={getShortName}
                  options={mentors}
                  loading={mentorsLoading}
                  forReadOnly
                  multiple
                />
              </FormColumnWrapper>
              <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
                <FormColumnTitle>{t('types.grade.education')}</FormColumnTitle>
                <FormikTextField
                  name="grade"
                  label={t('base.labels.grade')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormikTextField
                  name="comment"
                  label={t('base.labels.comment')}
                  placeholder={t('base.placeholders.readOnly')}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <FormikArrayTextField
                  name="documents"
                  values={values.documents}
                  label={t('base.labels.documents')}
                  placeholder={t('base.placeholders.readOnly')}
                  copyInputAdornment
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </FormColumnWrapper>
            </Stack>
          )}

          {gradeView === 'criteria' && (
            <FormColumnWrapper sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
              <FormColumnTitle>{t('types.grade.gradesCriteria')}</FormColumnTitle>
              <FieldArray
                name="criteria"
                render={() => (
                  <>
                    {values?.criteria?.map((criteria, index) => {
                      return (
                        <Stack key={index} direction="row" gap={2} sx={{ width: '100%' }}>
                          <TextField
                            name={`criteria.${index}.name`}
                            label={t('base.labels.criteriaName')}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              readOnly: true,
                            }}
                            value={criteria.name || ''}
                            sx={{ flex: 1 }}
                          />
                          <TextField
                            name={`criteria.${index}.weight`}
                            label={t('base.labels.criteriaWeight')}
                            InputProps={{
                              readOnly: true,
                            }}
                            InputLabelProps={{ shrink: true }}
                            value={criteria.weight || ''}
                            sx={{ flex: 0.2 }}
                          />
                          <TextField
                            name={`criteria.${index}.description`}
                            label={t('base.labels.criteriaDesc')}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                              readOnly: true,
                            }}
                            value={criteria.description || ''}
                            sx={{ flex: 1 }}
                          />
                          <FormikTextField
                            name={`criteria.${index}.grade`}
                            label={t('base.labels.grade')}
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{ flex: 0.2 }}
                          />
                        </Stack>
                      );
                    })}
                  </>
                )}
              />
            </FormColumnWrapper>
          )}
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ grade = {} }) => ({
      ...grade,
      id: grade?.id,
      assignment: grade?.assignment || null,
      student: grade?.student || null,
      group: grade?.group || null,
      mentors: grade?.group?.mentors || [],
      grade: grade?.grade || '',
      comment: grade?.comment || '',
      documents: grade?.documents || [],
      criteria:
        grade?.criteria?.map((criteria) => ({
          name: criteria?.name || '',
          weight: criteria?.weight || '',
          comment: criteria?.comment || '',
          grade: criteria?.grade || '',
          description: criteria?.description || '',
          id: criteria?.id,
        })) ||
        (grade?.assignment?.criteria || []).map((criteria) => ({
          name: criteria?.name || '',
          weight: criteria?.weight || '',
          comment: criteria?.comment || '',
          description: criteria?.description || '',
          grade: criteria?.grade || '',
          id: criteria?.id,
        })),
    }),

    enableReinitialize: true,
  })(GradeForm)
);
