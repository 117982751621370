import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Stack, TextField, useMediaQuery } from '@mui/material';

import { programActions, programSelectors, programThunks } from 'store/ducks/program';
import { dictionarySelectors } from 'store/ducks/dictionary';

import { FormColumnTitle, FormColumnWrapper } from 'views/common/StyledComponents';
import FormikTextField from 'views/form/FormikTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';

import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';

import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';
import { getActivePrograms, getProgramHours, getShortName } from 'utils/dataTable';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FormikArrayDateTime from 'views/form/FormikArrayDateTime';

const GroupFormFields = ({group, values}) => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const programs = useSelector(programSelectors.getPrograms());
  const programsLoading = useSelector(programSelectors.programsLoading());
  const enums = useSelector(dictionarySelectors.getEnums());
  const trainers = useSelector(dictionarySelectors.getTrainers());
  const mentors = useSelector(dictionarySelectors.getMentors());
  const coordinators = useSelector(dictionarySelectors.getCoordinators());

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(group, values?.program?.name, programThunks.fetchPrograms);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.program, programSelectors.getPrograms(), programActions.setPrograms);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={isDesktop ? 2 : 1}
      width="100%"
      sx={{ ...(isMobile && { overflowY: 'auto' }) }}
    >
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.group.info')}</FormColumnTitle>
        <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
        <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.groupStatuses} />
        <FormikAutocomplete name="format" label={t('base.labels.format')} options={enums.groupFormats} />
        <FormikAutocomplete
          name="program"
          label={t('base.labels.program')}
          onInputChange={(e, value) => {
            setSearchValue(value);
          }}
          /** --- Checking if it is existing group or a new one.
           * Depending on this condition we filter the options - Active programs or all programs --- */
          options={group ? programs.content : getActivePrograms(programs.content)}
          loading={programsLoading}
          placeholder={t('base.placeholders.typeForSearch')}
        />
        <TextField
          name="hours"
          label={t('base.labels.programHours')}
          placeholder={t('base.placeholders.readOnly')}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
          }}
          value={getProgramHours(programs, values)}
        />
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.group.schedule')}</FormColumnTitle>
        <FormikDatePicker name="startDate" label={t('base.labels.startDate')} />
        <FormikArrayDateTime
          name="schedule"
          label={t('base.labels.schedule')}
          values={values.schedule}
          daysOptions={enums.daysOfWeek}
        />
        <FormikDatePicker name="graduationDate" label={t('base.labels.graduationDate')} />
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.group.staff')}</FormColumnTitle>
        <FormikAutocomplete
          name="trainer"
          label={t('base.labels.trainer')}
          options={trainers}
          getCustomLabel={getShortName}
        />
        <FormikAutocomplete
          name="mentors"
          label={t('base.labels.mentor')}
          options={mentors}
          getCustomLabel={getShortName}
          multiple
        />
        <FormikAutocomplete
          name="coordinator"
          label={t('base.labels.coordinator')}
          options={coordinators}
          getCustomLabel={getShortName}
        />
      </FormColumnWrapper>
    </Stack>
  );
};

export default memo(GroupFormFields);
