import { Stack, Typography } from '@mui/material';
import filters from 'filters';
import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { reportSelectors } from 'store/ducks/report';
import { getFullName } from 'utils/dataTable';
import DataTable from 'views/common/DataTable';
import Loader from 'views/common/Loader';
import { DataTableWrapper } from 'views/common/StyledComponents';

const StudentGradesReport = () => {
  const { t } = useTranslation();
  const [, setProps] = useOutletContext();

  const studentGrades = useSelector(reportSelectors.getStudentGrades());
  const studentGradesLoading = useSelector(reportSelectors.studentGradesLoading()) && !studentGrades;

  const filter = useSelector(reportSelectors.getStudentGradesFilterData());

  useEffect(() => {
    setProps((prevProps) => ({
      ...prevProps,
      studentGradesReport: true,
      wordDoc: true,
    }));

    return () => {
      setProps((prevProps) => ({
        ...prevProps,
        studentGradesReport: false,
        wordDoc: null,
      }));
    };
  }, [setProps]);

  const columns = [
    {
      field: 'homeworkName',
      headerName: t('base.labels.assignment'),
      filterable: false,
      flex: 0.5,
      minWidth: 180,
    },
    {
      field: 'deadline',
      headerName: t('base.labels.deadline'),
      filterable: false,
      renderCell: ({ row: { deadline } }) => {
        const shortDate = filters.date(deadline);
        return <Typography>{shortDate}</Typography>;
      },
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'submission',
      headerName: t('base.labels.submissionDate'),
      filterable: false,
      renderCell: ({ row: { submission } }) => {
        const shortDate = filters.date(submission);
        return <Typography>{shortDate}</Typography>;
      },
      flex: 0.3,
      minWidth: 100,
    },
    {
      field: 'grade',
      headerName: t('base.labels.grade'),
      filterable: false,
      flex: 0.3,
      minWidth: 80,
    },
  ];

  return (
    <Stack height={'calc(100% - 90px)'}>
      {studentGradesLoading && <Loader />}
      {!studentGradesLoading && (
        <Stack gap={2} height="100%">
          <Stack sx={{ px: 2, py: 1, backgroundColor: 'extra.white', borderRadius: 2, minHeight: 80 }}>
            {!filter ? (
              <Typography variant="h6" color="text.main">
                {t('messages.warning.selectStudent')}
              </Typography>
            ) : (
              <>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2" color="text.main" fontWeight={600}>
                    {t('base.labels.student')}:
                  </Typography>
                  <Typography variant="body2" color="text.main">
                    {getFullName(filter?.student)}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2" color="text.main" fontWeight={600}>
                    {t('base.labels.program')}:
                  </Typography>
                  <Typography variant="body2" color="text.main">
                    {filter?.group?.program?.name}
                  </Typography>
                </Stack>
                <Stack direction="row" alignItems="center" gap={1}>
                  <Typography variant="body2" color="text.main" fontWeight={600}>
                    {t('base.labels.description')}:
                  </Typography>
                  <Typography variant="body2" color="text.main">
                    {filter?.group?.program?.description || '-'}
                  </Typography>
                </Stack>
              </>
            )}
          </Stack>
          <DataTableWrapper sx={{ height: 'calc(100% - 124px)' }}>
            <DataTable
              list={studentGrades}
              totalElements={studentGrades.length}
              isLoading={studentGradesLoading}
              columns={columns}
              getRowId={(row) => `${row.homeworkName}-${row.deadline}`}
              autoPageSize
            />
          </DataTableWrapper>
        </Stack>
      )}
    </Stack>
  );
};

export default memo(StudentGradesReport);
