import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Stack, TextField, Tooltip, useTheme } from '@mui/material';
import { BrowseFileIcon } from 'theme/icons';

const ImageUploader = ({ file, setFile, isSubmitting }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleChooseFile = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Stack direction="row" gap={theme.spacing(2)} width="100%" alignItems="center">
      <TextField
        label="Image"
        value={file?.name || ''}
        placeholder={t('base.placeholders.chooseFile')}
        sx={{ flex: 1 }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <label htmlFor={`upload-image`}>
              <Tooltip title={t('base.tooltips.browseFile')}>
                <IconButton
                  sx={{
                    p: 0,
                  }}
                  component="span"
                  disabled={isSubmitting}
                >
                  <BrowseFileIcon />
                </IconButton>
              </Tooltip>
              <input id={`upload-image`} hidden accept="image/*" type="file" onChange={handleChooseFile} />
            </label>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        disabled={isSubmitting}
      />
    </Stack>
  );
};

export default memo(ImageUploader);
