import { memo, useState } from 'react';
import { Form, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import store from 'store';
import { programThunks } from 'store/ducks/program';
import { uploadThunks } from 'store/ducks/upload';

import HeaderCard from 'views/common/HeaderCard';
import { PageWrapper } from 'views/common/StyledComponents';

import { PermissionContextProvider } from 'services/context/permissionContext';
import ProgramFormFields from './ProgramFormFields';
import ProgramModules from './ProgramModules';
import ProgramLessons from './ProgramLessons';
import ProgramAssignments from './ProgramAssignments';

import { validationProgramForm } from 'utils/schema';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const ProgramForm = ({ program, modalOpen, openModal, closeModal, isSubmitting, file, setFile, dirty }) => {
  const { t } = useTranslation();

  const [programView, setProgramView] = useState('data');

  const permission = program ? hasPermission(PERMISSIONS.program.update) : hasPermission(PERMISSIONS.program.create);
  const lessonPermission = hasPermission(PERMISSIONS.lesson.read);
  const assignmentPermission = hasPermission(PERMISSIONS.assignment.read);

  const handleProgramViewChange = (event, newView) => {
    if (programView === newView || newView === null) return;

    setProgramView(newView);
  };

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="programForm" style={{ height: '100%' }}>
          <HeaderCard
            title={program ? t('types.program.name') : t('types.program.new')}
            data={program}
            isSubmitting={isSubmitting}
            formId="programForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            isCreateNewModule={program}
            isFormChanged={dirty || file}
          />
          <Stack height="calc(100% - 68px)" gap={1} mt={{ lg: 1 }} sx={{ overflowY: 'auto' }}>
            {program && (
              <ToggleButtonGroup
                color="primary"
                value={programView}
                exclusive
                onChange={handleProgramViewChange}
                sx={{
                  paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
                  width: '100%',
                }}
              >
                <ToggleButton sx={{ width: '100%' }} value="data">
                  {t('base.dictionary.data')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="modules">
                  {t('base.labels.modules')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="lessons" disabled={!lessonPermission}>
                  {t('types.lesson.namePlural')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="assignments" disabled={!assignmentPermission}>
                  {t('types.assignment.namePlural')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {programView === 'data' && (
              <ProgramFormFields program={program} file={file} setFile={setFile} isSubmitting={isSubmitting} />
            )}
            {programView === 'modules' && <ProgramModules id={program?.id} />}
            {programView === 'lessons' && <ProgramLessons id={program?.id} />}
            {programView === 'assignments' && <ProgramAssignments id={program?.id} />}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ program = {} }) => ({
      ...program,
      id: program?.id,
      name: program?.name || '',
      hours: program?.hours || '',
      months: program?.months || '',
      status: program?.status || null,
      description: program?.description || '',
      image: program?.image || '',
      isFree: program?.isFree || false,
    }),
    validationSchema: validationProgramForm,

    handleSubmit: async (values, { props, setSubmitting }) => {
      const imageUrlRes = props.file ? await store.dispatch(uploadThunks.uploadImage(props.file)) : null;

      const imageUrl = imageUrlRes ? imageUrlRes.payload : '';

      const updatedValues = values.image && !imageUrl ? { ...values } : { ...values, image: imageUrl };

      const res = props.program
        ? await store.dispatch(programThunks.updateProgram(updatedValues))
        : await store.dispatch(programThunks.createProgram(updatedValues));

      if (!res.error) {
        props.file && props.setFile(null);
        toast.success(
          props.program
            ? props.t('messages.success.toast.updateProgram')
            : props.t('messages.success.toast.createProgram')
        );
        setSubmitting(false);

        if (props.program) {
          props.closeModal();
        } else props.navigate(`/programs/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(ProgramForm)
);
