import { memo, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import { Stack } from '@mui/material';

import store from 'store';
import { taskActions, taskSelectors, taskThunks } from 'store/ducks/task';

import Loader from 'views/common/Loader';
import TaskForm from './components/TaskForm';
import Error from 'views/common/Error';
import useModal from 'hooks/useModal';
import { PageWrapper } from 'views/common/StyledComponents';

const TaskCard = () => {
  const { id } = useParams();
  const [modalOpen, openModal, closeModal] = useModal();
  const task = useSelector(taskSelectors.getTask());
  const isLoading = useSelector(taskSelectors.taskLoading()) && !task;
  const { t } = useTranslation();
  /* --- state  to handle files uploading (file attachments to task) --- */
  const [files, setFiles] = useState(['']);

  useEffect(() => {
    store.dispatch(taskThunks.fetchTask(id));

    return () => store.dispatch(taskActions.clearItemWithInitialState({ collection: 'item', data: null }));
  }, [id]);

  return (
    <PageWrapper>
      {isLoading && <Loader />}
      {!isLoading && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <TaskForm
            task={task}
            modalOpen={modalOpen}
            closeModal={closeModal}
            openModal={openModal}
            files={files}
            setFiles={setFiles}
            t={t}
          />
        </ErrorBoundary>
      )}
    </PageWrapper>
  );
};

export default memo(TaskCard);
