import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Card, CardContent, CardMedia, Stack, Typography, useMediaQuery } from '@mui/material';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

import { dictionarySelectors } from 'store/ducks/dictionary';

import { FormColumnTitle, FormColumnWrapper } from 'views/common/StyledComponents';
import FormikTextField from 'views/form/FormikTextField';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikCheckbox from 'views/form/FormikCheckbox';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';
import ImageUploader from 'views/common/ImageUploader';

const ProgramFormFields = ({ program, file, setFile, isSubmitting }) => {
  const { t } = useTranslation();

  const enums = useSelector(dictionarySelectors.getEnums());

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      gap={isDesktop ? 2 : 1}
      width="100%"
      sx={{ ...(isMobile && { overflowY: 'auto' }) }}
    >
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.program.info')}</FormColumnTitle>
        <FormikTextField name="name" label={t('base.labels.name')} placeholder={t('base.placeholders.name')} />
        <FormikTextField name="hours" label={t('base.labels.hours')} placeholder={t('base.placeholders.hours')} />
        <FormikTextField name="months" label={t('base.labels.months')} placeholder={t('base.placeholders.months')} />
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.program.details')}</FormColumnTitle>
        <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.activeStatuses} />
        <FormikTextField
          name="description"
          label={t('base.labels.description')}
          placeholder={t('base.placeholders.description')}
          rows={3.5}
          multiline
        />
        <FormikCheckbox name="isFree" label={t('types.program.isFree')} sx={{ p: 0.5 }} />
      </FormColumnWrapper>
      <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
        <FormColumnTitle>{t('types.program.image')}</FormColumnTitle>
        <Card sx={{ backgroundColor: 'extra.white', mt: 1 }}>
          {program?.image ? (
            <CardMedia sx={{ height: 140 }} image={program?.image} />
          ) : (
            <CardContent>
              <ImageOutlinedIcon sx={{ color: 'text.light' }} />
              <Typography variant="body2" color="text.light">
                {t('messages.warning.noImage')}
              </Typography>
            </CardContent>
          )}
        </Card>

        <ImageUploader file={file} setFile={setFile} isSubmitting={isSubmitting} />
      </FormColumnWrapper>
    </Stack>
  );
};

export default memo(ProgramFormFields);
