import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from '@sentry/react';

import store from 'store';
import { sessionSelectors, sessionThunks } from 'store/ducks/session';

import HeaderList from 'views/common/HeaderList';
import SessionsList from 'views/common/SessionsList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { PageWrapper } from 'views/common/StyledComponents';
import Error from 'views/common/Error';
import { DESKTOP_VIEW } from 'utils/constants/constants';
import { useMediaQuery } from '@mui/material';
import HeaderPageMobile from 'views/common/HeaderPageMobile';

const SessionsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const sessions = useSelector(sessionSelectors.getSessions());
  const sessionsLoading = useSelector(sessionSelectors.sessionsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  const permission = hasPermission(PERMISSIONS.session.create);

  useEffect(() => {
    query && store.dispatch(sessionThunks.fetchSessions(`?${query}`));
  }, [query]);

  const navigateToCreateNewSessionPage = () => {
    navigate('/sessions/new');
  };

  const navigateToCreateBulkSessionsPage = () => {
    navigate('/sessions/bulk');
  };

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        {isDesktop ? (
          <HeaderList
            title={t('types.session.namePlural')}
            createNewItemAction={navigateToCreateNewSessionPage}
            buttonTitle={'+ ' + t('base.buttons.createSession')}
            hasPermission={permission}
            bulkButtonTitle={t('base.buttons.bulkSessions')}
            bulkTo={navigateToCreateBulkSessionsPage}
            isSearchbarShow
          />
        ) : (
          <HeaderPageMobile
            createNewItemAction={navigateToCreateNewSessionPage}
            isSearchbarShow
            hasPermission={permission}
            bulkTo={navigateToCreateBulkSessionsPage}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <SessionsList
          list={sessions.content}
          isLoading={sessionsLoading}
          totalElements={sessions.totalElements}
          totalPages={sessions.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(SessionsPage);
