import { IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../theme/icons/searchIcon.svg';
import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addSearchPhrase } from 'store/ducks/table/slice';
import { tableSelectors } from 'store/ducks/table';
import { useTranslation } from 'react-i18next';
import { DESKTOP_VIEW } from 'utils/constants/constants';

const SearchBar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const searchPhrase = useSelector(tableSelectors.getSearchPhrase());
  const [phrase, setPhrase] = useState('');
  const { t } = useTranslation();
  const handleChange = useCallback(
    (event) => {
      setPhrase(event.target.value);
    },
    [setPhrase]
  );
  /** --- Fix the losing of the last letter in search input --- */
  useEffect(() => {
    handleChange;
  }, [handleChange]);

  const showResults = (e) => {
    if (e.key === 'Enter' || e.type === 'click') {
      dispatch(addSearchPhrase(phrase));
    }
  };
  useEffect(() => {
    searchPhrase ? setPhrase(searchPhrase) : setPhrase('');
  }, [searchPhrase]);

  return (
    <TextField
      variant="outlined"
      type="search"
      name="q"
      autoComplete="off"
      sx={{
        flex: 1,
        ...(!isDesktop && {
          '& .MuiOutlinedInput-root': {
            backgroundColor: 'extra.white',
          },
        }),
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position={'end'} sx={{ ml: 0 }}>
            <IconButton onClick={showResults}>
              <SearchIcon stroke={theme.palette.primary.main} fill="none" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      placeholder={t('base.placeholders.search')}
      onChange={handleChange}
      onKeyDown={showResults}
      value={phrase}
    />
  );
};

export default memo(SearchBar);
