import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from 'store';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';

import PersonsList from 'views/common/PersonsList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const GroupHistory = ({ group }) => {
  const history = useSelector(groupSelectors.getGroupHistory());
  const historyLoading = useSelector(groupSelectors.groupHistoryLoading());

  const historyMembers = history.map((option) => {
    return { ...option.student, from: option.from, to: option.to };
  });

  const membersAndHistoryPermission = hasPermission(PERMISSIONS.person.read);

  useEffect(() => {
    membersAndHistoryPermission && store.dispatch(groupThunks.fetchGroupHistory(group.id));
  }, [group, membersAndHistoryPermission]);

  useEffect(() => {
    return () => store.dispatch(groupActions.clearItemWithInitialState({ collection: 'groupHistory', data: [] }));
  }, []);

  return (
    <PersonsList
      list={historyMembers}
      totalElements={historyMembers.length}
      columnVisibilityModel={{
        roles: false,
        idCode: false,
        birthDate: false,
      }}
      isLoading={historyLoading}
      hideFooter
      isNested
    />
  );
};

export default memo(GroupHistory);
