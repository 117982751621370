import { memo } from 'react';
import { useSelector } from 'react-redux';

import { programSelectors } from 'store/ducks/program';
import ProgramModulesList from './programModules/ProgramModulesList';

const ProgramModules = ({ id }) => {
  const program = useSelector(programSelectors.getProgram(id));
  const programLoading = useSelector(programSelectors.programLoading());

  return (
    <ProgramModulesList
      list={program.modules}
      totalElements={program.modules.length}
      isLoading={programLoading}
      programId={program.id}
      hideFooter
      isNested
    />
  );
};

export default memo(ProgramModules);
