import { createAsyncThunk } from '@reduxjs/toolkit';
import api, { v1 } from 'services/api';
import { loadingFinished, updateUser } from './slice';
import router from 'routes/router';
import { toast } from 'react-toastify';

import i18next from 'i18next';

const t = i18next.t;

const fetchUser = createAsyncThunk('user/fetchCurrent', async (_, { dispatch }) => {
  return api
    .get('/auth/current')
    .then((res) => {
      setTimeout(() => {
        dispatch(loadingFinished());
      }, 0);
      return res;
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        v1.deleteToken();
      }

      dispatch(loadingFinished());
    });
});

const login = createAsyncThunk('user/login', async (data) => {
  return api.post('/auth/login', data);
});

const logout = createAsyncThunk('user/logout', (_, { dispatch }) => {
  v1.deleteToken();
  dispatch({ type: 'logout/LOGOUT' });
  window.location.reload();
});

const forgotPassword = createAsyncThunk('user/forgotPassword', async (data) => {
  return api.post('/auth/forgot', data).then(() => {
    toast.success(t('messages.success.toast.forgotPassword'));
  });
});

const resetPassword = createAsyncThunk('user/resetPassword', async (data) => {
  return api.post('/auth/reset', data).then(() => {
    toast.success(t('messages.success.toast.resetPassword'));
    router.navigate('/login');
  });
});

const changePassword = createAsyncThunk('user/changePassword', async (data) => {
  return api.put('/users/password', data);
});

const updateUserAvatar = createAsyncThunk('user/updateUserAvatar', (data, { dispatch }) => {
  return api.put('/users/profile', data).then((res) => {
    dispatch(updateUser({ collection: 'current', data: res }));
  });
});

export default {
  fetchUser,
  login,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  updateUserAvatar,
};
