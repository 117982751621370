import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';

import DataTable from 'views/common/DataTable';
import Modal from 'views/common/modal/Modal';
import ProgramModulesForm from './ProgramModulesForm';
import { DataTableWrapper } from 'views/common/StyledComponents';

import useModal from 'hooks/useModal';
import { STATUSES_COLOR } from 'utils/constants/constants';

const ProgramModulesList = ({
  list,
  totalElements,
  isLoading,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  programId,
  isNested,
}) => {
  const { t } = useTranslation();
  const [modalOpen, openModal, closeModal] = useModal();
  const [module, setModule] = useState(null);

  const handleClick = (id) => {
    const programModule = list.find((option) => option.id === id);
    programModule && setModule(programModule);
    openModal();
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Button
            variant="text"
            sx={{ px: 0, color: 'text.main', minWidth: '100%', justifyContent: 'left' }}
            onClick={() => handleClick(id)}
          >
            {id}
          </Button>
        );
      },
    },
    { field: 'number', headerName: t('base.labels.number'), filterable: false, minWidth: 80, flex: 0.3 },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, minWidth: 160, flex: 0.4 },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status?.name] || 'text.main';
        return <Typography color={statusColor}>{status?.label}</Typography>;
      },
    },
  ];

  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)', overflowY: 'auto' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
      />

      <Modal title={t('messages.warning.editModule')} questionIcon open={modalOpen} onClose={closeModal}>
        <ProgramModulesForm closeModal={closeModal} module={module} programId={programId} t={t} />
      </Modal>
    </DataTableWrapper>
  );
};

export default memo(ProgramModulesList);
