import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import useModal from 'hooks/useModal';
import { DateTime } from 'luxon';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useOutletContext, useSearchParams } from 'react-router-dom';
import store from 'store';
import { logSelectors, logThunks } from 'store/ducks/log';
import { getShortName } from 'utils/dataTable';
import DataTable from 'views/common/DataTable';
import Modal from 'views/common/modal/Modal';
import JsonView from '@uiw/react-json-view';
import { useTranslation } from 'react-i18next';

const LogsPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const logs = useSelector(logSelectors.getLogs());
  const logsLoading = useSelector(logSelectors.logsLoading()) && !logs;
  const [modalOpen, openModal, closeModal] = useModal();
  const [modalData, setModalData] = useState({});
  const [, setProps] = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(logThunks.fetchLogs(`?${query}`));
  }, [query]);

  const handleModal = (before, after, entity, action) => {
    if (before !== undefined) {
      const beforeObj = before !== undefined ? JSON.parse(before) : null;
      const afterObj = JSON.parse(after);
      setModalData({ before: beforeObj, after: afterObj, entity: entity, action: action });
      openModal();
    }
  };

  useEffect(() => {
    setProps((prevProps) => ({ ...prevProps, search: true }));

    return () => {
      setProps((prevProps) => ({ ...prevProps, search: false }));
    };
  }, [setProps]);

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      flex: 0.3,
      minWidth: 80,
      renderCell: ({ row: { id, before, after, entity, action } }) => {
        return (
          <Button
            sx={{ minWidth: 40, maxWidth: 60, border: 'none', color: 'text.primary', '&:hover': { border: 'none' } }}
            onClick={() => handleModal(before, after, entity, action)}
          >
            {id}
          </Button>
        );
      },
    },
    { field: 'action', headerName: t('types.settings.logs.action'), filterable: false, minWidth: 80, flex: 0.3 },
    { field: 'entity', headerName: t('types.settings.logs.entity'), filterable: false, minWidth: 120, flex: 0.4 },
    {
      field: 'entityId',
      headerName: t('types.settings.logs.entityId'),
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { entityId, entity } }) => {
        return (
          <Link
            to={`/${entity.toLowerCase()}s/${entityId}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {entityId}
          </Link>
        );
      },
    },
    {
      field: 'author',
      headerName: t('base.labels.author'),
      filterable: false,
      minWidth: 160,
      flex: 0.5,
      renderCell: ({ row: { author } }) => <Typography>{getShortName(author)}</Typography>,
    },

    {
      field: 'createdAt',
      headerName: t('base.labels.date'),
      filterable: false,
      minWidth: 160,
      flex: 0.5,
      renderCell: ({ row: { createdAt } }) => {
        return <Typography>{DateTime.fromISO(createdAt).toFormat('dd.MM.yyyy HH:mm:ss')}</Typography>;
      },
    },
  ];

  return (
    <Box height="calc(100% - 76px)">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          p: 2,
          backgroundColor: 'extra.white',
          borderRadius: 2,
        }}
      >
        <DataTable
          list={logs.content}
          totalElements={logs.totalElements}
          isLoading={logsLoading}
          columns={columns}
          totalPages={logs.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </Box>
      <Modal
        title={`${t('types.settings.logs.detailsOf')} ${modalData.entity} ${modalData.action}`}
        open={modalOpen}
        onClose={closeModal}
        maxWidth={'800px'}
      >
        <Stack direction="row" spacing={4} mx={6} my={4}>
          <Box>
            <Typography variant="h5" color="primary.main" textAlign="center" mb={3}>
              {t('types.settings.logs.before')}
            </Typography>
            <JsonView
              value={modalData.before}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              objectSortKeys
              collapsed={2}
            />
          </Box>
          <Box>
            <Typography variant="h5" color="primary.main" textAlign="center" mb={3}>
              {t('types.settings.logs.after')}
            </Typography>
            <JsonView
              value={modalData.after}
              displayDataTypes={false}
              displayObjectSize={false}
              enableClipboard={false}
              objectSortKeys
              collapsed={2}
            />
          </Box>
        </Stack>
      </Modal>
    </Box>
  );
};

export default memo(LogsPage);
