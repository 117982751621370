import { memo, useEffect, useState } from 'react';
import { Form, withFormik } from 'formik';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Stack, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';

import store from 'store';
import { dictionarySelectors } from 'store/ducks/dictionary';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';
import { programActions, programSelectors, programThunks } from 'store/ducks/program';
import { sessionThunks } from 'store/ducks/session';
import { useTranslation } from 'react-i18next';

import HeaderCard from 'views/common/HeaderCard';
import { FormColumnTitle, FormColumnWrapper, PageWrapper } from 'views/common/StyledComponents';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import FormikDateTimePicker from 'views/form/FormikDateTimePicker';

import { PermissionContextProvider } from 'services/context/permissionContext';
import { useAddEntityIfNotExists } from 'hooks/useAddEntityIfNotExists';
import { useAutocompleteSearch } from 'hooks/useAutocompleteSearch';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { getActiveGroups, getNameAndNumber, getShortName } from 'utils/dataTable';
import { checkFieldDateForChanges } from 'utils/helpers';
import { validationSessionForm } from 'utils/schema';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';
import SessionAssignments from './SessionAssignments';

const SessionForm = ({
  session,
  modalOpen,
  openModal,
  closeModal,
  isSubmitting,
  values,
  handleUpdateSession,
  handleUpdateBulkSessions,
  setSubmitting,
  dirty,
}) => {
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const [sessionView, setSessionView] = useState('data');

  const groups = useSelector(groupSelectors.getGroups());
  const groupsLoading = useSelector(groupSelectors.groupsLoading());
  const trainers = useSelector(dictionarySelectors.getTrainers());
  const lessons = useSelector(programSelectors.getProgramLessons());
  const lessonsLoading = useSelector(programSelectors.programLessonsLoading());

  const permission = session ? hasPermission(PERMISSIONS.session.update) : hasPermission(PERMISSIONS.session.create);

  /** --- Custom hook for handling server search in Autocomplete --- */
  const [setSearchValue] = useAutocompleteSearch(session, values?.group?.name, groupThunks.fetchGroups);

  /** --- Custom hook for handling fetch data and set it in the list of options in Autocomplete --- */
  useAddEntityIfNotExists(values?.group, groupSelectors.getGroups(), groupActions.setGroups);

  /** --- Getting program lessons.  */
  useEffect(() => {
    !values?.group?.program
      ? store.dispatch(programActions.clearItemWithInitialState({ collection: 'programLessons', data: [] }))
      : store.dispatch(programThunks.fetchProgramLessons(values?.group?.program?.id));
  }, [values.group]);

  /** --- Check if the date is changed --- */
  const isDateChanged = checkFieldDateForChanges(session?.date, values.date);

  const handleSessionViewChange = (event, newView) => {
    if (sessionView === newView || newView === null) return;

    setSessionView(newView);
  };

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="sessionForm" style={{ height: '100%' }}>
          <HeaderCard
            title={session ? t('types.session.name') : t('types.session.new')}
            data={session}
            isSubmitting={isSubmitting}
            formId="sessionForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            isDateChanged={isDateChanged}
            handleUpdateSession={handleUpdateSession}
            handleUpdateBulkSessions={handleUpdateBulkSessions}
            formValues={values}
            setSubmitting={setSubmitting}
            isFormChanged={dirty}
          />
          <Stack height="calc(100% - 76px)" gap={1} sx={{ overflowY: 'auto' }} mt={{ lg: 1 }}>
            {session && (
              <ToggleButtonGroup
                color="primary"
                value={sessionView}
                exclusive
                onChange={handleSessionViewChange}
                sx={{
                  paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
                  width: '100%',
                }}
              >
                <ToggleButton sx={{ width: '100%' }} value="data">
                  {t('base.dictionary.data')}
                </ToggleButton>
                <ToggleButton
                  sx={{ width: '100%' }}
                  value="assignments"
                  disabled={!hasPermission(PERMISSIONS.assignment.read)}
                >
                  {t('types.assignment.namePlural')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {sessionView === 'data' && (
              <Stack
                direction={isMobile ? 'column' : 'row'}
                gap={isDesktop ? 2 : 1}
                width="100%"
                sx={{ ...(isMobile && { overflowY: 'auto' }) }}
              >
                <FormColumnWrapper sx={{ width: isMobile ? '100%' : '50%' }}>
                  <FormColumnTitle>{t('types.session.lessonInfo')}</FormColumnTitle>
                  <FormikAutocomplete
                    name="group"
                    label={t('base.labels.group')}
                    onInputChange={(event, value) => {
                      setSearchValue(value);
                    }}
                    options={session ? groups.content : getActiveGroups(groups.content)}
                    loading={groupsLoading}
                    placeholder={t('base.placeholders.typeForSearch')}
                  />
                  <TextField
                    name="program"
                    label={t('base.labels.program')}
                    placeholder={t('base.placeholders.readOnly')}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={values?.group?.program?.name || ''}
                  />
                  <FormikAutocomplete
                    name="trainer"
                    label={t('base.labels.trainer')}
                    getCustomLabel={getShortName}
                    options={trainers}
                  />
                  <FormikAutocomplete
                    name="lesson"
                    label={t('base.labels.sessionTopic')}
                    getCustomLabel={getNameAndNumber}
                    options={lessons}
                    loading={lessonsLoading}
                  />
                  <FormikTextField
                    name="link"
                    label={t('base.labels.sessionLink')}
                    placeholder={t('base.placeholders.pasteLink')}
                    InputLabelProps={{ shrink: true }}
                  />
                </FormColumnWrapper>
                <FormColumnWrapper sx={{ width: isMobile ? '100%' : '50%' }}>
                  <FormColumnTitle>{t('types.session.sessionInfo')}</FormColumnTitle>
                  <TextField
                    name="plannedDuration"
                    label={t('base.labels.plannedDuration')}
                    placeholder={t('base.placeholders.readOnly')}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      readOnly: true,
                    }}
                    value={values?.lesson?.plannedDuration || ''}
                  />
                  <FormikDateTimePicker name="date" label={t('base.labels.date')} />
                  <FormikTextField
                    name="duration"
                    label={t('base.labels.durationMins')}
                    placeholder={t('base.placeholders.sessionDuration')}
                  />
                  <FormikTextField
                    name="record"
                    label={t('base.labels.recordLink')}
                    placeholder={t('base.placeholders.pasteLink')}
                  />
                </FormColumnWrapper>
              </Stack>
            )}
            {sessionView === 'assignments' && <SessionAssignments session={session} />}
            {/* {session && hasPermission(PERMISSIONS.assignment.read) && <SessionDetails session={session} />} */}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ session = {} }) => ({
      ...session,
      id: session?.id,
      group: session?.group || null,
      trainer: session?.trainer || null,
      lesson: session?.lesson || null,
      link: session?.link || '',
      date: session?.date || null,
      plannedDuration: session?.plannedDuration || '',
      duration: session?.duration || '',
      record: session?.record || '',
    }),
    validationSchema: validationSessionForm,

    handleSubmit: (values, { props, setSubmitting }) => {
      const isDateChanged = checkFieldDateForChanges(props.session?.date, values.date);

      if (props.session) {
        if (isDateChanged) {
          return;
        } else props.handleUpdateSession(values, setSubmitting);
      } else
        store
          .dispatch(sessionThunks.createSession(values))
          .then(
            (res) =>
              !res.error &&
              (setSubmitting(false),
              toast.success(props.t('messages.success.toast.createSession')),
              props.navigate(`/sessions/${res.payload.id}`))
          );
    },
    enableReinitialize: true,
  })(SessionForm)
);
