import { Fragment, memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Chip, Stack, Typography, useTheme } from '@mui/material';

import { DataTableWrapper } from './StyledComponents';
import DataTable from './DataTable';

import { getLastNameAndNameLetter } from 'utils/dataTable';
import { STATUSES_COLOR } from 'utils/constants/constants';

const GroupsList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  autoPageSize,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/groups/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    { field: 'name', headerName: t('base.labels.name'), filterable: false, minWidth: 80, flex: 0.3 },
    {
      field: 'format',
      headerName: t('base.labels.format'),
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { format } }) => {
        return <Typography color={format.name === 'ONLINE' ? 'success.main' : 'error'}>{format.label}</Typography>;
      },
    },
    { field: 'since', headerName: t('base.labels.since'), filterable: false, minWidth: 120, flex: 0.3 },
    {
      field: 'program',
      headerName: t('base.labels.program'),
      filterable: false,
      minWidth: 160,
      flex: 0.4,
      renderCell: ({ row: { program } }) => {
        return <Typography>{program.name}</Typography>;
      },
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
    },
    {
      field: 'schedule',
      headerName: t('base.labels.schedule'),
      filterable: false,
      minWidth: 160,
      flex: 0.4,
      renderCell: ({ row: { schedule } }) => {
        return (
          <Stack direction="row" spacing={0.5} flexWrap="nowrap">
            {schedule.map((item) => {
              return (
                <Fragment key={item?.day?.name}>
                  <Chip variant="outlined" label={item?.day?.label} />
                </Fragment>
              );
            })}
          </Stack>
        );
      },
    },
    {
      field: 'trainers',
      headerName: t('base.labels.trainer'),
      filterable: false,
      minWidth: 140,
      flex: 0.4,
      renderCell: ({ row: { trainer } }) => {
        return <Typography>{getLastNameAndNameLetter(trainer)}</Typography>;
      },
    },
    {
      field: 'coordinators',
      headerName: t('base.labels.coordinator'),
      filterable: false,
      minWidth: 140,
      flex: 0.4,
      renderCell: ({ row: { coordinator } }) => {
        return <Typography>{getLastNameAndNameLetter(coordinator)}</Typography>;
      },
    },
  ];

  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
        autoPageSize={autoPageSize}
      />
    </DataTableWrapper>
  );
};

export default memo(GroupsList);
