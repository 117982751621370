import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { Box, Container, useMediaQuery, useTheme } from '@mui/material';

import SideBar from 'views/common/sidebar/SideBar';
import Error from 'views/common/Error';
import TabletTopBar from 'views/common/topbar/TabletTopBar';
import MobileTopBar from 'views/common/topbar/MobileTopBar';

const PageLayout = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  return (
    <Box display="flex" width="100%">
      {/* --- aside menu component --- */}
      {isDesktop && (
        <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
          <SideBar />
        </ErrorBoundary>
      )}
      {isDesktop ? (
        <Container sx={{ maxWidth: { xl: 'none' } }}>
          <Outlet />
        </Container>
      ) : (
        <Container disableGutters>
          <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
            {/** --- Mobile view --- */}
            {isTablet ? <TabletTopBar /> : <MobileTopBar />}
          </ErrorBoundary>
          <Outlet />
        </Container>
      )}
    </Box>
  );
};

export default PageLayout;
