import filters from 'filters';
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import GroupsList from 'views/common/GroupsList';

const PersonGroups = ({ personId }) => {
  const groups = useSelector(personSelectors.getPersonGroups());
  const groupsLoading = useSelector(personSelectors.personGroupsLoading());

  const groupPermission = hasPermission(PERMISSIONS.group.read);

  useEffect(() => {
    if (personId) {
      groupPermission && store.dispatch(personThunks.fetchPersonGroups(personId));
    }
  }, [personId, groupPermission]);

  useEffect(() => {
    return () => store.dispatch(personActions.clearItemWithInitialState({ collection: 'userGroups', data: [] }));
  }, []);

  const groupsList =
    !groupsLoading &&
    groups.map((option) => {
      return { ...option.group, since: filters.date(option.createdAt) };
    });

  return (
    <GroupsList list={groupsList || []} totalElements={groups.length} isLoading={groupsLoading} hideFooter isNested />
  );
};

export default memo(PersonGroups);
