import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import AssignmentsList from 'views/common/AssignmentsList';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const ProgramAssignments = ({ id }) => {
  const programAssignments = useSelector(programSelectors.getProgramAssignments());
  const programAssignmentsLoading = useSelector(programSelectors.programAssignmentsLoading());

  const assignmentPermission = hasPermission(PERMISSIONS.assignment.read);

  useEffect(() => {
    assignmentPermission && store.dispatch(programThunks.fetchProgramAssignments(id));
  }, [id, assignmentPermission]);

  useEffect(() => {
    return () =>
      store.dispatch(programActions.clearItemWithInitialState({ collection: 'programAssignments', data: [] }));
  }, []);

  return (
    <AssignmentsList
      list={programAssignments}
      totalElements={programAssignments.length}
      isLoading={programAssignmentsLoading}
      columnVisibilityModel={{
        program: false,
      }}
      hideFooter
      isNested
    />
  );
};

export default memo(ProgramAssignments);
