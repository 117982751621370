import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { programActions, programSelectors, programThunks } from 'store/ducks/program';

import LessonsList from 'views/common/LessonsList';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const ProgramLessons = ({ id }) => {
  const programLessons = useSelector(programSelectors.getProgramLessons());
  const programLessonsLoading = useSelector(programSelectors.programLessonsLoading());

  const lessonPermission = hasPermission(PERMISSIONS.lesson.read);

  useEffect(() => {
    lessonPermission && store.dispatch(programThunks.fetchProgramLessons(id));
  }, [id, lessonPermission]);

  useEffect(() => {
    return () => store.dispatch(programActions.clearItemWithInitialState({ collection: 'programLessons', data: [] }));
  }, []);

  return (
    <LessonsList
      list={programLessons}
      totalElements={programLessons.length}
      columnVisibilityModel={{
        program: false,
      }}
      isLoading={programLessonsLoading}
      hideFooter
      isNested
    />
  );
};

export default memo(ProgramLessons);
