import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import PersonForm from './components/PersonForm';
import Error from 'views/common/Error';

const NewPerson = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedContact, setSelectedContact] = useState(null);

  return (
    <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
      <PersonForm navigate={navigate} t={t} selectedContact={selectedContact} setSelectedContact={setSelectedContact} />
    </ErrorBoundary>
  );
};
export default memo(NewPerson);
