import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { AddCircleOutlineOutlined } from '@mui/icons-material';

import { BulkIcon, ColumnsIcon, ExportIcon, ListIcon } from 'theme/icons';
import SearchBar from './SearchBar';
import ColumnIndicators from './ColumnIndicators';
import TasksFilter from 'views/pages/tasks/components/TasksFilter';

import { MOBILE_VIEW } from 'utils/constants/constants';
import { tableSelectors } from 'store/ducks/table';
import { useSelector } from 'react-redux';
import { exportToExcel } from 'utils/helpers';

const HeaderPageMobile = ({
  isSearchbarShow,
  onSearch,
  pageView,
  togglePageView,
  createNewItemAction,
  tasksFilter,
  handleFilterChange,
  currentColumnIndex,
  columnIndicator,
  indicatorColumnsList,
  hasPermission,
  bulkTo,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(MOBILE_VIEW);

  const selectedData = useSelector(tableSelectors.getSelected());

  const handleExport = () => {
    exportToExcel(selectedData);
  };

  const { t } = useTranslation();
  return (
    <Stack px={1} pt={1} pb={0} width="100%" gap={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {isSearchbarShow && <SearchBar onSearch={onSearch} />}
        {pageView && !tasksFilter && (
          <Tooltip
            title={pageView === 'list' ? t('base.tooltips.switchToColView') : t('base.tooltips.switchToListView')}
          >
            <IconButton onClick={togglePageView}>
              {pageView === 'list' ? (
                <ListIcon fill={theme.palette.primary.main} />
              ) : (
                <ColumnsIcon fill={theme.palette.primary.main} />
              )}
            </IconButton>
          </Tooltip>
        )}
        {createNewItemAction && hasPermission && !tasksFilter && (
          <IconButton onClick={createNewItemAction} sx={{ color: 'primary.main' }}>
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        {bulkTo && (
          <IconButton onClick={bulkTo}>
            <BulkIcon fill={theme.palette.primary.main} width={24} height={24} />
          </IconButton>
        )}
        <IconButton onClick={handleExport}>
          <ExportIcon fill={selectedData?.length === 0 ? theme.palette.action.disabled : theme.palette.primary.main} />
        </IconButton>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {createNewItemAction && hasPermission && tasksFilter && (
          <IconButton onClick={createNewItemAction} sx={{ color: 'primary.main' }}>
            <AddCircleOutlineOutlined />
          </IconButton>
        )}
        <Stack direction="row" alignItems="center">
          {tasksFilter && (
            <>
              <Tooltip
                title={pageView === 'list' ? t('base.tooltips.switchToColView') : t('base.tooltips.switchToListView')}
              >
                <IconButton onClick={togglePageView}>
                  {pageView === 'list' ? (
                    <ListIcon fill={theme.palette.primary.main} />
                  ) : (
                    <ColumnsIcon fill={theme.palette.primary.main} />
                  )}
                </IconButton>
              </Tooltip>
              <TasksFilter filter={tasksFilter} handleFilter={handleFilterChange} pageView={pageView} />
            </>
          )}
        </Stack>
      </Stack>

      {isMobile && columnIndicator && (
        <ColumnIndicators columns={indicatorColumnsList} currentIndex={currentColumnIndex} />
      )}
    </Stack>
  );
};

export default memo(HeaderPageMobile);
