import { memo, useEffect, useState } from 'react';

import TasksGridView from './components/TasksGridView';
import TasksList from './components/TasksList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { getDataFromLS } from 'utils/helpers';
import { useNavigate, useSearchParams } from 'react-router-dom';

const TasksPage = () => {
  const navigate = useNavigate();

  const tasksView = getDataFromLS('tasksPageView');
  const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line no-unused-vars

  const [pageView, setPageView] = useState(tasksView);

  const [filter, setFilter] = useState({
    my: true,
    byMe: false,
    notMy: false,
    type: null,
  });

  const handleFilterChange = (eventOrValue, name) => {
    if (eventOrValue === null) {
      setFilter({ ...filter, type: null });
      return;
    }

    if (eventOrValue.target) {
      setFilter({
        ...filter,
        [eventOrValue.target.name]: eventOrValue.target.checked,
      });
    } else {
      setFilter({
        ...filter,
        [name]: eventOrValue,
      });
    }
  };

  useEffect(() => {
    localStorage.setItem('tasksPageView', JSON.stringify(pageView));
  }, [pageView]);

  const togglePageView = () => {
    setPageView((prevPageView) => {
      const newPageView = prevPageView === 'list' ? 'col' : 'list';
      setSearchParams({});
      return newPageView;
    });
  };

  const navigateToCreateNewTaskPage = () => {
    navigate('/tasks/new');
  };

  return pageView === 'list' ? (
    <TasksList
      handleFilterChange={handleFilterChange}
      filter={filter}
      pageView={pageView}
      togglePageView={togglePageView}
      hasPermission={hasPermission(PERMISSIONS.task.create)}
      createNewItemAction={navigateToCreateNewTaskPage}
    />
  ) : (
    <TasksGridView
      handleFilterChange={handleFilterChange}
      filter={filter}
      pageView={pageView}
      togglePageView={togglePageView}
      hasPermission={hasPermission(PERMISSIONS.task.create)}
      createNewItemAction={navigateToCreateNewTaskPage}
    />
  );
};

export default memo(TasksPage);
