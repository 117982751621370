import { Fragment, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { userSelectors } from 'store/ducks/user';

import DrawerMobile from './DrawerMobile';
import { MenuClosedMobileIcon, MenuOpenMobileIcon } from 'theme/icons';
import defaultLogoShort from '../../../theme/icons/short-dan-logo-white.svg';
import { SIDEBAR_MENU } from 'utils/constants/menuSidebar';
import { hasPermission } from 'utils/roles';

const TabletTopBar = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const location = useLocation();
  const user = useSelector(userSelectors.getCurrentUser());
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');

  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const handleMenuItemClick = (key) => {
    setSelected(key);
    setOpen(false);
  };

  useEffect(() => {
    const menuItem = SIDEBAR_MENU.find((el) => el.to.includes(rootPath));
    setSelected(menuItem?.key);
  }, [rootPath, setSelected]);

  return (
    <Stack
      sx={{
        background: theme.palette.extra.gradient,
        p: 2,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
      }}
    >
      {/* --- LOGO & MENU BTN ---  */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <img src={theme.logoShort || defaultLogoShort} />
        <Typography variant="subtitle2" color="extra.white">
          {open ? t('base.dictionary.menu') : t(`mainMenu.${selected}`)}
        </Typography>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <MenuOpenMobileIcon /> : <MenuClosedMobileIcon />}
        </IconButton>
      </Stack>
      {/* --- DRAWER ---  */}
      <DrawerMobile onClose={() => setOpen(false)} open={open}>
        <Stack p={2} gap={2}>
          {/* --- AVATAR AND USER SETTINGS BUTTONS ---  */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ width: 44, height: 44 }} alt={user.name} src={user.avatar} />
              <Box>
                <Typography variant="body1" color="extra.white" fontWeight={700}>
                  {user.lastName} {user.name}
                </Typography>

                <Typography variant="capture" color="extra.white" display={open ? 'flex' : 'none'}>
                  {user.roles?.at(-1).label}
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />

          {/* --- MENU ---  */}
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {SIDEBAR_MENU.map((menuItem) => {
              return (
                <Fragment key={menuItem.key}>
                  {hasPermission(menuItem.permission) && (
                    <ListItem disableGutters sx={{ py: 0 }}>
                      <ListItemButton
                        sx={{
                          color: 'extra.white',
                          flexDirection: 'column',
                          alignItems: 'center',
                        }}
                        component={Link}
                        to={menuItem.to}
                        onClick={() => handleMenuItemClick(menuItem.key)}
                        disableGutters
                      >
                        <ListItemIcon
                          sx={{
                            color: menuItem.to === rootPath ? 'primary.main' : 'extra.white',
                            minWidth: 0,
                            backgroundColor: menuItem.to === rootPath ? 'extra.white' : 'transparent',
                            padding: 1,
                            borderRadius: 2,
                          }}
                        >
                          {menuItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={t(`mainMenu.${menuItem.key}`)}
                          sx={{
                            color: 'extra.white',
                            '&>.MuiTypography-root': {
                              fontSize: 14,
                              fontWeight: menuItem.to === rootPath ? 700 : 400,
                            },
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                </Fragment>
              );
            })}
          </Stack>
        </Stack>
      </DrawerMobile>
    </Stack>
  );
};

export default TabletTopBar;
