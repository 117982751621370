import { memo, useState } from 'react';
import { Form, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './formik-quill-custom.css';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import HeaderCard from 'views/common/HeaderCard';
import { PageWrapper } from 'views/common/StyledComponents';
import AssignmentFormFields from './AssignmentFormFields';
import AssignmentGradeCriteria from './AssignmentGradeCriteria';

const AssignmentForm = ({ assignment, assignmentLoading, values }) => {
  const { t } = useTranslation();

  const [assignmentView, setAssignmentView] = useState('data');

  const handleAssignmentViewChange = (event, newView) => {
    if (assignmentView === newView || newView === null) return;

    setAssignmentView(newView);
  };

  return (
    <PageWrapper>
      <Form id="assignmentForm" style={{ height: '100%' }}>
        <HeaderCard title={t('types.assignment.name')} />
        <Stack height="calc(100% - 78px)" gap={1} sx={{ overflowY: 'auto', mt: { md: 1 } }}>
          <ToggleButtonGroup
            color="primary"
            value={assignmentView}
            exclusive
            onChange={handleAssignmentViewChange}
            sx={{
              paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
              width: '100%',
            }}
          >
            <ToggleButton sx={{ width: '100%' }} value="data">
              {t('base.dictionary.data')}
            </ToggleButton>
            <ToggleButton sx={{ width: '100%' }} value="description">
              {t('base.labels.description')}
            </ToggleButton>
            <ToggleButton sx={{ width: '100%' }} value="criteria">
              {t('types.assignment.gradeCriteria')}
            </ToggleButton>
          </ToggleButtonGroup>
          {assignmentView === 'data' && <AssignmentFormFields values={values} />}
          {assignmentView === 'description' && (
            <ReactQuill theme="snow" value={assignment?.description} modules={{ toolbar: false }} readOnly={true} />
          )}
          {assignmentView === 'criteria' && (
            <AssignmentGradeCriteria
              list={assignment.criteria}
              totalElements={assignment.criteria.length}
              isLoading={assignmentLoading}
              assignmentId={assignment.id}
              hideFooter
              isNested
            />
          )}
        </Stack>
      </Form>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ assignment = {} }) => ({
      ...assignment,
      id: assignment?.id,
      number: assignment?.number || '',
      name: assignment?.name || '',
      program: assignment?.program?.name || '',
      module: assignment?.module?.name || '',
      lesson: assignment?.lesson?.name || '',
      status: assignment?.status?.label || '',
      documents: assignment?.documents || [],
      type: assignment?.type?.label || '',
      requirement: assignment?.requirement?.label || '',
      deadlineDays: assignment?.deadlineDays || '',
    }),

    handleSubmit: () => {},
    enableReinitialize: true,
  })(AssignmentForm)
);
