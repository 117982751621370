import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import PersonsList from 'views/common/PersonsList';

const GroupMembers = ({ group }) => {
  const members = useSelector(groupSelectors.getGroupMembers());
  const membersLoading = useSelector(groupSelectors.groupMembersLoading());

  const membersAndHistoryPermission = hasPermission(PERMISSIONS.person.read);

  useEffect(() => {
    membersAndHistoryPermission && store.dispatch(groupThunks.fetchGroupMembers(group.id));
  }, [group, membersAndHistoryPermission]);

  useEffect(() => {
    return () => store.dispatch(groupActions.clearItemWithInitialState({ collection: 'groupMembers', data: [] }));
  }, []);
  return (
    <PersonsList
      list={members}
      totalElements={members.length}
      columnVisibilityModel={{
        roles: false,
        idCode: false,
        birthDate: false,
        from: false,
        to: false,
      }}
      isLoading={membersLoading}
      hideFooter
      isNested
    />
  );
};

export default memo(GroupMembers);
