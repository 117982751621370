import { memo, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { useMediaQuery } from '@mui/material';

import store from 'store';
import { gradeSelectors, gradeThunks } from 'store/ducks/grade';

import GradesList from 'views/common/GradesList';
import HeaderList from 'views/common/HeaderList';
import Error from 'views/common/Error';
import { PageWrapper } from 'views/common/StyledComponents';
import { DESKTOP_VIEW } from 'utils/constants/constants';
import HeaderPageMobile from 'views/common/HeaderPageMobile';

const GradesPage = () => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const grades = useSelector(gradeSelectors.getGrades());
  const gradesLoading = useSelector(gradeSelectors.gradesLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(gradeThunks.fetchGrades(`?${query}`));
  }, [query]);

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        {isDesktop ? (
          <HeaderList title={t('types.grade.namePlural')} isSearchbarShow />
        ) : (
          <HeaderPageMobile isSearchbarShow />
        )}
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <GradesList
          list={grades.content}
          isLoading={gradesLoading}
          totalElements={grades.totalElements}
          totalPages={grades.totalPages}
          setSearchParams={setSearchParams}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(GradesPage);
