import { Fragment, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Chip, Stack, useTheme } from '@mui/material';

import { DataTableWrapper } from './StyledComponents';
import DataTable from './DataTable';

const PersonsList = ({
  list,
  isLoading,
  totalElements,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  columnVisibilityModel,
  hideFooter,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/persons/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    {
      field: 'lastName',
      headerName: t('base.labels.lastName'),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
      renderCell: ({ row: { contact } }) => contact.lastName,
    },
    {
      field: 'name',
      headerName: t('base.labels.name'),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
      renderCell: ({ row: { contact } }) => contact.name,
    },
    {
      field: 'secondName',
      headerName: t('base.labels.secondName'),
      filterable: false,
      minWidth: 200,
      flex: 0.5,
      renderCell: ({ row: { contact } }) => contact.secondName,
    },
    { field: 'birthDate', headerName: t('base.labels.dateOfBirth'), filterable: false, minWidth: 120, flex: 0.3 },
    {
      field: 'roles',
      headerName: t('base.labels.rolePlural'),
      filterable: false,
      minWidth: 200,
      flex: 1,
      renderCell: ({ row: { roles } }) => (
        <Stack direction="row" spacing={0.5} flexWrap="nowrap">
          {roles.map((role) => {
            return (
              <Fragment key={role.name}>
                <Chip
                  variant="outlined"
                  sx={{
                    borderColor:
                      role.name === 'ROLE_SUPERADMIN'
                        ? 'success.main'
                        : role.label === 'Client'
                        ? 'primary.main'
                        : 'warning.main',
                    color:
                      role.name === 'ROLE_SUPERADMIN'
                        ? 'success.main'
                        : role.label === 'Client'
                        ? 'primary.main'
                        : 'warning.main',
                  }}
                  label={role.label}
                />
              </Fragment>
            );
          })}
        </Stack>
      ),
    },
    { field: 'idCode', headerName: t('base.labels.idCode'), filterable: false, minWidth: 140, flex: 0.4 },
    { field: 'from', headerName: t('base.labels.from'), filterable: false, minWidth: 120, flex: 0.3 },
    { field: 'to', headerName: t('base.labels.to'), filterable: false, minWidth: 120, flex: 0.3 },
  ];

  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={columnVisibilityModel}
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
      />
    </DataTableWrapper>
  );
};

export default memo(PersonsList);
