import { memo, useState } from 'react';
import { Form, withFormik } from 'formik';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import store from 'store';
import { groupThunks } from 'store/ducks/group';

import { PermissionContextProvider } from 'services/context/permissionContext';

import { PageWrapper } from 'views/common/StyledComponents';
import HeaderCard from 'views/common/HeaderCard';
import GroupFormFields from './GroupFormFields';
import GroupMembers from './GroupMembers';
import GroupSessions from './GroupSessions';
import GroupHistory from './GroupHistory';

import { hasPermission } from 'utils/roles';
import { validationGroupForm } from 'utils/schema';
import { PERMISSIONS } from 'utils/constants/permissions';

const GroupForm = ({ group, modalOpen, openModal, closeModal, isSubmitting, values, dirty }) => {
  const { t } = useTranslation();

  const [groupView, setGroupView] = useState('data');

  const permission = group ? hasPermission(PERMISSIONS.group.update) : hasPermission(PERMISSIONS.group.create);

  const membersAndHistoryPermission = hasPermission(PERMISSIONS.person.read);
  const sessionPermission = hasPermission(PERMISSIONS.session.read);

  const handleGroupViewChange = (event, newView) => {
    if (groupView === newView || newView === null) return;

    setGroupView(newView);
  };

  return (
    <PageWrapper>
      <PermissionContextProvider value={permission}>
        <Form id="groupForm" style={{ height: '100%' }}>
          <HeaderCard
            title={group ? t('types.group.name') : t('types.group.new')}
            data={group}
            isSubmitting={isSubmitting}
            formId="groupForm"
            modalOpen={modalOpen}
            openModal={openModal}
            closeModal={closeModal}
            hasPermission={permission}
            isFormChanged={dirty}
          />
          <Stack height="calc(100% - 60px)" gap={1} mt={{ lg: 1 }} sx={{ overflowY: 'auto' }}>
            {group && (
              <ToggleButtonGroup
                color="primary"
                value={groupView}
                exclusive
                onChange={handleGroupViewChange}
                sx={{
                  paddingX: { lg: 0, md: 2, sm: 2, xs: 1 },
                  width: '100%',
                }}
              >
                <ToggleButton sx={{ width: '100%' }} value="data">
                  {t('base.dictionary.data')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="members" disabled={!membersAndHistoryPermission}>
                  {t('types.group.members')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="sessions" disabled={!sessionPermission}>
                  {t('types.session.namePlural')}
                </ToggleButton>
                <ToggleButton sx={{ width: '100%' }} value="history" disabled={!membersAndHistoryPermission}>
                  {t('types.group.history')}
                </ToggleButton>
              </ToggleButtonGroup>
            )}
            {groupView === 'data' && <GroupFormFields group={group} values={values} />}

            {groupView === 'members' && <GroupMembers group={group} />}
            {groupView === 'sessions' && <GroupSessions group={group} />}
            {groupView === 'history' && <GroupHistory group={group} />}
          </Stack>
        </Form>
      </PermissionContextProvider>
    </PageWrapper>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: ({ group = {} }) => ({
      ...group,
      id: group?.id,
      name: group?.name || '',
      program: group?.program || null,
      status: group?.status || null,
      startDate: group?.startDate || null,
      graduationDate: group?.graduationDate || null,
      schedule: group?.schedule || [],
      trainer: group?.trainer || null,
      mentors: group?.mentors || [],
      coordinator: group?.coordinator || null,
      format: group?.format || null,
    }),
    validationSchema: validationGroupForm,
    handleSubmit: async (values, { props, setSubmitting }) => {
      const res = props.group
        ? await store.dispatch(groupThunks.updateGroup(values))
        : await store.dispatch(groupThunks.createGroup(values));

      if (!res.error) {
        toast.success(
          props.group ? props.t('messages.success.toast.updateGroup') : props.t('messages.success.toast.createGroup')
        );
        setSubmitting(false);

        if (props.group) {
          props.closeModal();
        } else props.navigate(`/groups/${res.payload.id}`);
      }
    },
    enableReinitialize: true,
  })(GroupForm)
);
