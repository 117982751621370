import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import store from 'store';

import { personActions, personSelectors, personThunks } from 'store/ducks/person';
import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import ContractsList from 'views/common/ContractsList';

const PersonContracts = ({ personId }) => {
  const contracts = useSelector(personSelectors.getPersonContracts());
  const contractsLoading = useSelector(personSelectors.personContractsLoading());

  const contractPermission = hasPermission(PERMISSIONS.contract.read);

  useEffect(() => {
    if (personId) {
      contractPermission && store.dispatch(personThunks.fetchPersonContracts(personId));
    }
  }, [personId, contractPermission]);

  useEffect(() => {
    return () => store.dispatch(personActions.clearItemWithInitialState({ collection: 'userContracts', data: [] }));
  }, []);

  return (
    <ContractsList
      list={contracts}
      totalElements={contracts.length}
      columnVisibilityModel={{
        contractor: false,
      }}
      isLoading={contractsLoading}
      hideFooter
      isNested
    />
  );
};

export default memo(PersonContracts);
