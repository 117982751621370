import { memo } from 'react';
import { Divider, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

import { SortUpDownIcon } from 'theme/icons';
import TaskColumnCard from './TaskColumnCard';
import { MOBILE_VIEW } from 'utils/constants/constants';

const TasksColumn = ({ column, list }) => {
  const isMobile = useMediaQuery(MOBILE_VIEW);
  const theme = useTheme();

  return (
    <Stack
      key={column.title}
      px={1}
      py={1}
      height="100%"
      borderRadius={2}
      minWidth={isMobile ? '100%' : '0%'}
      maxWidth={isMobile ? '100%' : '25%'}
      boxShadow={`0px 0px 4px 0px ${theme.palette.primary.lighter}`}
      sx={{ backgroundColor: 'secondary.lightest', flex: 1 }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography variant="h6" color="text.dark">
            {column.title}
          </Typography>
          <Typography color="text.main">({list.length})</Typography>
        </Stack>
        <IconButton>
          <SortUpDownIcon />
        </IconButton>
      </Stack>
      <Divider sx={{ borderColor: 'background.default', borderWidth: '1px', my: 1 }} />

      <Stack sx={{ overflowY: 'auto' }} gap={1}>
        {list.map((item) => (
          <TaskColumnCard key={item.id} task={item} />
        ))}
      </Stack>
    </Stack>
  );
};
export default memo(TasksColumn);
