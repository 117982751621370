import { Stack, TextField, useMediaQuery } from '@mui/material';
import FormikAutocomplete from 'views/form/FormikAutocomplete';
import FormikTextField from 'views/form/FormikTextField';
import FormikArrayTextField from 'views/form/FormikArrayTextField';
import FormikDatePicker from 'views/form/FormikDatePicker';
import FilesUploader from 'views/form/FilesUploader';
import { getActiveGroups, getActivePrograms, getFullName, getShortName } from 'utils/dataTable';
import { memo } from 'react';
import { FormColumnTitle, FormColumnWrapper } from 'views/common/StyledComponents';
import { useTranslation } from 'react-i18next';
import { DESKTOP_VIEW, MOBILE_VIEW } from 'utils/constants/constants';

const ContractFormFields = ({
  values,
  clients,
  clientsLoading,
  managers,
  legalEntities,
  enums,
  programs,
  programsLoading,
  groups,
  groupsLoading,
  setStudentSearchValue,
  setContractorSearchValue,
  setProgramSearchValue,
  setGroupSearchValue,
  defaultValue,
  files,
  setFiles,
  getGroupCoordinator,
  getProgramHours,
  isSubmitting,
  contract,
}) => {
  const { t } = useTranslation();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);
  const isMobile = useMediaQuery(MOBILE_VIEW);

  return (
    <Stack gap={1}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        gap={isDesktop ? 2 : 1}
        width="100%"
        sx={{ ...(isMobile && { overflowY: 'auto' }) }}
      >
        {/* --- PERSONAL DATA COLUMN ---*/}
        <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
          <FormColumnTitle>{t('types.contract.personalInfo')}</FormColumnTitle>
          <FormikAutocomplete
            name="student"
            label={t('base.labels.student')}
            onInputChange={(e, value) => {
              setStudentSearchValue(value);
            }}
            getCustomLabel={getFullName}
            options={clients.content}
            loading={clientsLoading}
            placeholder={t('base.placeholders.typeForSearch')}
          />
          <FormikAutocomplete
            name="contractor"
            label={t('base.labels.contractor')}
            onInputChange={(e, value) => {
              setContractorSearchValue(value);
            }}
            getCustomLabel={getFullName}
            options={clients.content}
            loading={clientsLoading}
            placeholder={t('base.placeholders.typeForSearch')}
            defaultValue={defaultValue}
          />
          <FormikAutocomplete
            name="responsible"
            label={t('base.labels.responsible')}
            getCustomLabel={getShortName}
            options={managers}
          />
          <FormikAutocomplete name="legalEntity" label={t('base.labels.legalEntity')} options={legalEntities} />
          <FormikArrayTextField
            name="documents"
            values={values.documents}
            label={t('base.labels.documents')}
            placeholder={t('base.placeholders.pasteLink')}
            copyInputAdornment
          />
        </FormColumnWrapper>
        {/* --- CONTRACT INFO COLUMN ---*/}
        <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
          <FormColumnTitle>{t('types.contract.contractInfo')}</FormColumnTitle>
          <FormikTextField name="number" label={t('base.labels.number')} placeholder={t('base.placeholders.number')} />
          <FormikAutocomplete name="status" label={t('base.labels.status')} options={enums.contractStatuses} />
          <FormikAutocomplete
            name="paymentMethod"
            label={t('base.labels.paymentMethod')}
            options={enums.paymentMethods}
          />
          <FormikTextField
            name="amount"
            label={t('base.labels.contractAmount')}
            placeholder={t('base.placeholders.contractAmount')}
          />
          <FormikDatePicker name="date" label={t('base.labels.contractDate')} />
          <FilesUploader
            files={files}
            setFiles={setFiles}
            fileSize="15000000"
            filesAccept=".pdf, .ppt, .pptx, .txt, .xls, .xlsx, .doc, .docx, .zip, .mp4, .mpeg, .avi, .jpeg, .jpg, .png"
            isLoading={isSubmitting}
            uploadedFiles={values?.files}
          />
        </FormColumnWrapper>
        {/* --- EDUCATION DATA COLUMN ---*/}
        <FormColumnWrapper sx={{ width: isMobile ? '100%' : '33.33%' }}>
          <FormColumnTitle>{t('types.contract.education')}</FormColumnTitle>
          <FormikAutocomplete
            name="program"
            label={t('base.labels.program')}
            loading={programsLoading}
            options={contract ? programs.content : getActivePrograms(programs.content)}
            onInputChange={(e, value) => {
              setProgramSearchValue(value);
            }}
            placeholder={t('base.placeholders.typeForSearch')}
          />
          <FormikAutocomplete
            name="modules"
            label={t('base.labels.modules')}
            options={values?.program?.modules || []}
            limitTags={1}
            multiple
          />
          <FormikAutocomplete
            name="group"
            label={t('base.labels.group')}
            loading={groupsLoading}
            options={contract ? groups.content : getActiveGroups(groups.content)}
            onInputChange={(e, value) => {
              setGroupSearchValue(value);
            }}
            placeholder={t('base.placeholders.typeForSearch')}
          />
          <TextField
            name="coordinator"
            label={t('base.labels.coordinator')}
            placeholder={t('base.placeholders.readOnly')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            value={getGroupCoordinator()}
          />
          <TextField
            name="startDate"
            label={t('base.labels.startDate')}
            placeholder={t('base.placeholders.readOnly')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            value={values?.group?.startDate || ''}
          />
          <TextField
            name="hours"
            label={t('base.labels.programHours')}
            placeholder={t('base.placeholders.readOnly')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            value={getProgramHours(programs, values)}
          />
        </FormColumnWrapper>
      </Stack>

      <FormColumnWrapper sx={{ width: '100%', py: 1 }}>
        <FormikTextField
          name="comment"
          label={t('base.labels.comment')}
          placeholder={t('base.placeholders.comment')}
          multiline
          rows={3}
        />
      </FormColumnWrapper>
    </Stack>
  );
};

export default memo(ContractFormFields);
