import { memo, useEffect } from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import store from 'store';
import { groupSelectors, groupThunks } from 'store/ducks/group';

import Error from 'views/common/Error';
import GroupsList from 'views/common/GroupsList';
import HeaderList from 'views/common/HeaderList';
import { PageWrapper } from 'views/common/StyledComponents';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';
import { DESKTOP_VIEW } from 'utils/constants/constants';
import { useMediaQuery } from '@mui/material';
import HeaderPageMobile from 'views/common/HeaderPageMobile';

const GroupsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const groups = useSelector(groupSelectors.getGroups());
  const groupsLoading = useSelector(groupSelectors.groupsLoading());

  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.toString();

  const pageQuery = parseInt(searchParams.get('page') || 0);
  const pageSizeQuery = parseInt(searchParams.get('size') || 15);
  const searchQuery = searchParams.get('q') || '';
  const sortByQuery = searchParams.get('sortBy') || '';
  const sortDirectionQuery = searchParams.get('direction') || '';

  useEffect(() => {
    query && store.dispatch(groupThunks.fetchGroups(`?${query}`));
  }, [query]);

  const navigateToCreateNewGroupPage = () => {
    navigate('/groups/new');
  };

  return (
    <PageWrapper>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        {isDesktop ? (
          <HeaderList
            title={t('types.group.namePlural')}
            createNewItemAction={navigateToCreateNewGroupPage}
            buttonTitle={'+ ' + t('base.buttons.createGroup')}
            isSearchbarShow
            hasPermission={hasPermission(PERMISSIONS.group.create)}
          />
        ) : (
          <HeaderPageMobile
            isSearchbarShow
            createNewItemAction={navigateToCreateNewGroupPage}
            hasPermission={hasPermission(PERMISSIONS.group.create)}
          />
        )}
      </ErrorBoundary>
      <ErrorBoundary fallback={<Error message={t('messages.errors.failedLoadComponent')} />}>
        <GroupsList
          list={groups.content}
          isLoading={groupsLoading}
          totalElements={groups.totalElements}
          totalPages={groups.totalPages}
          setSearchParams={setSearchParams}
          columnVisibilityModel={{
            since: false,
          }}
          pageQuery={pageQuery}
          pageSizeQuery={pageSizeQuery}
          searchQuery={searchQuery}
          sortByQuery={sortByQuery}
          sortDirectionQuery={sortDirectionQuery}
        />
      </ErrorBoundary>
    </PageWrapper>
  );
};

export default memo(GroupsPage);
