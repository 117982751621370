import { memo } from 'react';
import { Form, withFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import i18next from 'i18next';

import { Button, Stack, TextField } from '@mui/material';

import store from 'store';
import { userSelectors, userThunks } from 'store/ducks/user';

import PasswordInput from 'views/form/PasswordInput';
import { validationChangePasswordForm } from 'utils/schema';

const LoginDetails = ({ isSubmitting }) => {
  const user = useSelector(userSelectors.getCurrentUser());
  const { t } = useTranslation();

  return (
    <Form id="changePassword" style={{ height: '100%' }}>
      <Stack gap={1} height="100%">
        {user.emails?.map((email) => (
          <TextField
            key={email}
            label={t('base.labels.email')}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              readOnly: true,
            }}
            value={email}
          />
        ))}
        <PasswordInput
          variant="standard"
          inputLabelProps={{ shrink: true }}
          name="password"
          label={t('base.labels.oldPassword')}
          placeholder={t('base.placeholders.oldPassword')}
        />
        <PasswordInput
          variant="standard"
          inputLabelProps={{ shrink: true }}
          name="newPassword"
          label={t('base.labels.newPassword')}
          placeholder={t('base.placeholders.newPassword')}
        />
        <PasswordInput
          variant="standard"
          inputLabelProps={{ shrink: true }}
          name="confirmPassword"
          label={t('base.labels.newPassword')}
          placeholder={t('base.placeholders.confirmNewPassword')}
        />
        <Button sx={{ mt: 'auto' }} variant="text" type="submit" disabled={isSubmitting}>
          {t('base.buttons.changePassword')}
        </Button>
      </Stack>
    </Form>
  );
};

export default memo(
  withFormik({
    mapPropsToValues: () => ({
      password: '',
      newPassword: '',
      confirmPassword: '',
    }),
    validationSchema: validationChangePasswordForm,
    handleSubmit: async (values, { setSubmitting }) => {
      const passwordData = {
        password: values.password,
        newPassword: values.newPassword,
      };

      const res = await store.dispatch(userThunks.changePassword(passwordData));

      if (!res.error) {
        setSubmitting(false), toast.success(i18next.t('messages.success.toast.changePassword'));
      }
    },
    enableReinitialize: true,
  })(LoginDetails)
);
