import { memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Packer } from 'docx';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import { Button, CircularProgress, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

import ConfirmModal from './modal/ConfirmModal';
import Modal from './modal/Modal';
import { exportToExcel } from 'utils/helpers';
import { ExportIcon } from 'theme/icons';
import CreateNewModule from 'views/pages/programs/components/programModules/CreateNewModule';
import { DESKTOP_VIEW } from 'utils/constants/constants';
import { ChevronLeftOutlined } from '@mui/icons-material';

/** --- Header component of Forms ---  */

const HeaderCard = ({
  title,
  data,
  formId,
  modalOpen,
  closeModal,
  openModal,
  isSubmitting,
  hasPermission,
  cloneBtn,
  handleClone,
  doc,
  isDateChanged,
  handleUpdateSession,
  handleUpdateBulkSessions,
  setSubmitting,
  formValues,
  isCreateNewModule,
  isFormChanged,
}) => {
  //TODO: CHECK ANOTHER PROPS AND ELEMENTS FOR DIFFERENT ENTITIES
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  const goBack = () => navigate(-1);

  const handleExport = () => {
    exportToExcel(data);
  };

  const saveDocument = (doc, contract) => {
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `Agreement-${contract.number}.docx`);
    });
  };

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      px={2}
      sx={{
        borderBottomLeftRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        ...(isDesktop && { backgroundColor: 'extra.white' }),
      }}
    >
      {isDesktop && (
        <Stack direction="row" alignItems="center" gap={2}>
          <Button variant="contained" startIcon={<ArrowBackIosNewOutlinedIcon />} LinkComponent={Link} onClick={goBack}>
            {t('base.buttons.back')}
          </Button>

          <Typography variant="h4" color="text.main">
            {title}
          </Typography>
        </Stack>
      )}

      {!isDesktop && (
        <>
          <IconButton LinkComponent={Link} onClick={goBack} sx={{ p: 0, color: 'primary.main' }}>
            <ChevronLeftOutlined fontSize="large" />
          </IconButton>
          <Typography variant={isDesktop ? 'h4' : 'h6'} color="text.main" sx={{ textAlign: 'center' }}>
            {title}
          </Typography>
        </>
      )}

      <Stack direction="row" alignItems="center" gap={{ lg: 2, xs: 1 }}>
        {/* Check if we create new item or edit current one */}
        {data && hasPermission && isFormChanged && (
          <Button
            variant={isDesktop ? 'contained' : 'text'}
            sx={{
              justifyContent: isDesktop ? 'center' : 'right',
              fontWeight: isDesktop ? 400 : 700,
              ...(!isDesktop && { minWidth: 0 }),
            }}
            onClick={() => openModal()}
          >
            {t('base.buttons.apply')}
          </Button>
        )}
        {!data && hasPermission && (
          <Button
            startIcon={isSubmitting ? <CircularProgress size={16} sx={{ mr: 1, color: 'extra.white' }} /> : null}
            variant={isDesktop ? 'contained' : 'text'}
            type="submit"
            disabled={isSubmitting}
            form={formId}
            sx={{
              justifyContent: isDesktop ? 'center' : 'right',
              fontWeight: isDesktop ? 400 : 700,
              ...(!isDesktop && { minWidth: 0 }),
            }}
          >
            {isSubmitting ? t('base.buttons.creating') : t('base.buttons.create')}
          </Button>
        )}
        {cloneBtn && hasPermission && (
          <Button
            variant={isDesktop ? 'contained' : 'text'}
            sx={{
              justifyContent: isDesktop ? 'center' : 'right',
              fontWeight: isDesktop ? 400 : 700,
              ...(!isDesktop && { minWidth: 0 }),
            }}
            onClick={() => handleClone()}
          >
            {t('base.buttons.clone')} {cloneBtn}
          </Button>
        )}
        {isDateChanged ? (
          <Modal
            attentionIcon
            open={modalOpen}
            onClose={closeModal}
            title={t('messages.warning.changedDateSession')}
            actions={
              <>
                <Button onClick={() => handleUpdateSession(formValues, setSubmitting)}> {t('base.buttons.no')}</Button>
                <Button variant="contained" onClick={() => handleUpdateBulkSessions(formValues, setSubmitting)}>
                  {t('base.buttons.yes')}
                </Button>
              </>
            }
          >
            <Typography variant="h6" color="warning.main" mx={3} my={2}>
              {t('messages.warning.updateNextSessions')}
            </Typography>
          </Modal>
        ) : (
          data && (
            <ConfirmModal
              title="Save changes?"
              open={modalOpen}
              onClose={closeModal}
              formId={formId}
              saveDisabled={isSubmitting}
              confirmTitle={isSubmitting ? t('base.buttons.saving') : t('base.buttons.save')}
              questionIcon
            />
          )
        )}
        {doc && (
          <Button
            onClick={() => saveDocument(doc, data)}
            variant={isDesktop ? 'outlined' : 'text'}
            sx={{ ...(!isDesktop && { minWidth: 0 }) }}
          >
            <FileDownloadOutlinedIcon sx={{ color: 'primary.main' }} />
            {isDesktop && t('base.dictionary.contract')}
          </Button>
        )}
        {data && (
          <Button
            variant={isDesktop ? 'outlined' : 'text'}
            sx={{
              justifyContent: isDesktop ? 'center' : 'right',
              fontWeight: isDesktop ? 400 : 700,
              ...(!isDesktop && { minWidth: 0 }),
              '& .MuiButton-startIcon': {
                marginRight: { md: 1, xs: 0 },
              },
            }}
            startIcon={<ExportIcon fill={theme.palette.primary.main} />}
            onClick={handleExport}
          >
            {isDesktop && t('base.buttons.export')}
          </Button>
        )}
        {isCreateNewModule && <CreateNewModule programId={data?.id} />}
      </Stack>
    </Stack>
  );
};

export default memo(HeaderCard);
