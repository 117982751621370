import { Fragment, memo, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

import store from 'store';
import { userSelectors, userThunks } from 'store/ducks/user';
import { MenuClosedMobileIcon, MenuOpenMobileIcon } from 'theme/icons';
import DrawerMobile from './DrawerMobile';
import { SIDEBAR_MENU } from 'utils/constants/menuSidebar';
import { hasPermission } from 'utils/roles';

const MobileMenu = ({ open, setOpen, setSelected }) => {
  const user = useSelector(userSelectors.getCurrentUser());

  const location = useLocation();
  const path = location.pathname.split('/');
  const rootPath = '/' + path[1];

  const { t } = useTranslation();

  const handleMenuItemClick = (title) => {
    setSelected(title);
    setOpen(false);
  };

  useEffect(() => {
    const menuItem = SIDEBAR_MENU.find((el) => el.to.includes(rootPath));
    setSelected(menuItem?.key);
  }, [rootPath, setSelected]);

  return (
    <>
      <IconButton onClick={() => setOpen(!open)}>{open ? <MenuOpenMobileIcon /> : <MenuClosedMobileIcon />}</IconButton>
      <DrawerMobile open={open} onClose={() => setOpen(false)}>
        <Stack p={2} gap={2}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" spacing={2} alignItems="center">
              <Avatar sx={{ width: 24, height: 24 }} alt={user.name} src={user.avatar} />
              <Typography variant="body2" color="extra.white" fontWeight={700}>
                {user.lastName} {user.name}
              </Typography>
            </Stack>
            <Typography variant="capture" color="extra.white" display={open ? 'flex' : 'none'}>
              {user.roles?.at(-1).label}
            </Typography>
          </Stack>
          <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />
          <Stack>
            {SIDEBAR_MENU.map((menuItem) => {
              return (
                <Fragment key={menuItem.key}>
                  {hasPermission(menuItem.permission) && (
                    <ListItem disableGutters sx={{ py: 0 }}>
                      <ListItemButton
                        sx={{ color: 'extra.white' }}
                        component={Link}
                        to={menuItem.to}
                        onClick={() => handleMenuItemClick(menuItem.key)}
                        disableGutters
                      >
                        <ListItemIcon sx={{ color: 'extra.white' }}>{menuItem.icon}</ListItemIcon>
                        <ListItemText
                          primary={t(`mainMenu.${menuItem.key}`)}
                          sx={{
                            color: menuItem.to === rootPath ? 'primary.main' : 'extra.white',
                            backgroundColor: menuItem.to === rootPath ? 'extra.white' : 'transparent',
                            borderRadius: 1,
                            pl: 2,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  )}
                </Fragment>
              );
            })}
          </Stack>
          <Divider sx={{ borderColor: 'rgba(231, 232, 255, 0.20)' }} />
          <Stack>
            <ListItem disableGutters sx={{ py: 0 }}>
              <ListItemButton
                sx={{ color: 'extra.white' }}
                onClick={() => store.dispatch(userThunks.logout())}
                disableGutters
              >
                <ListItemIcon sx={{ color: 'extra.white' }}>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText
                  primary={t('base.buttons.logout')}
                  sx={{
                    borderRadius: 1,
                    pl: 2,
                  }}
                />
              </ListItemButton>
            </ListItem>
          </Stack>
        </Stack>
      </DrawerMobile>
    </>
  );
};

export default memo(MobileMenu);
