import { Stack, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PageWrapper } from 'views/common/StyledComponents';
import HeaderSettings from 'views/pages/settings/HeaderSettings';
import SettingsMenu from 'views/pages/settings/SettingsMenu';
import { DESKTOP_VIEW } from 'utils/constants/constants';

const SettingsLayout = () => {
  const [props, setProps] = useState({
    search: false,
    buttonTitle: '',
    handleButtonClick: null,
  });

  const isDesktop = useMediaQuery(DESKTOP_VIEW);

  return (
    <PageWrapper>
      {isDesktop ? (
        <HeaderSettings
          search={props.search}
          buttonTitle={props.buttonTitle}
          handleButtonClick={props.handleButtonClick}
        />
      ) : (
        <Stack justifyContent="center">
          <SettingsMenu />
        </Stack>
      )}
      <Outlet context={[props, setProps]} />
    </PageWrapper>
  );
};

export default SettingsLayout;
