import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import store from 'store';
import { groupActions, groupSelectors, groupThunks } from 'store/ducks/group';

import SessionsList from 'views/common/SessionsList';

import { PERMISSIONS } from 'utils/constants/permissions';
import { hasPermission } from 'utils/roles';

const GroupSessions = ({ group }) => {
  const sessions = useSelector(groupSelectors.getGroupSessions());
  const sessionsLoading = useSelector(groupSelectors.groupSessionsLoading());

  const sessionPermission = hasPermission(PERMISSIONS.session.read);

  useEffect(() => {
    sessionPermission && store.dispatch(groupThunks.fetchGroupSessions(group.id));
  }, [group, sessionPermission]);

  useEffect(() => {
    return () => store.dispatch(groupActions.clearItemWithInitialState({ collection: 'groupSessions', data: [] }));
  }, []);

  return (
    <SessionsList
      list={sessions}
      totalElements={sessions.length}
      isLoading={sessionsLoading}
      columnVisibilityModel={{
        group: false,
      }}
      hideFooter
      isNested
    />
  );
};

export default memo(GroupSessions);
