import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Typography, useTheme } from '@mui/material';

import { DataTableWrapper } from './StyledComponents';
import DataTable from './DataTable';

import filters from 'filters';
import { getFullName } from 'utils/dataTable';
import { STATUSES_COLOR } from 'utils/constants/constants';

const InvoicesList = ({
  list,
  isLoading,
  totalElements,
  columnVisibilityModel,
  totalPages,
  setSearchParams,
  pageQuery,
  pageSizeQuery,
  searchQuery,
  sortByQuery,
  sortDirectionQuery,
  hideFooter,
  managerQuery,
  periodQuery,
  isNested,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      filterable: false,
      minWidth: 80,
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Link
            to={`/invoices/${id}`}
            style={{ textDecoration: 'none', color: theme.palette.text.dark, width: '100%', cursor: 'pointer' }}
          >
            {id}
          </Link>
        );
      },
    },
    { field: 'number', headerName: '№', filterable: false, minWidth: 80, flex: 0.3 },
    {
      field: 'contractor',
      headerName: t('base.labels.contractor'),
      filterable: false,
      renderCell: ({ row: { contract } }) => {
        return <Typography>{getFullName(contract.contractor)}</Typography>;
      },
      minWidth: 240,
      flex: 0.5,
    },
    {
      field: 'contract',
      headerName: t('base.labels.contract'),
      filterable: false,
      renderCell: ({ row: { contract } }) => {
        return <Typography>{contract.number}</Typography>;
      },
      minWidth: 80,
      flex: 0.3,
    },
    {
      field: 'status',
      headerName: t('base.labels.status'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
      renderCell: ({ row: { status } }) => {
        const statusColor = STATUSES_COLOR[status.name] || 'text.main';
        return <Typography color={statusColor}>{status.label}</Typography>;
      },
    },
    {
      field: 'dueDate',
      headerName: t('base.labels.dueDate'),
      filterable: false,
      minWidth: 120,
      flex: 0.3,
      renderCell: ({ row: { dueDate, status } }) => {
        const shortDate = filters.date(dueDate);
        const today = new Date();
        const isOverdue = today.getTime() > new Date(dueDate).getTime();
        return (
          <Typography color={isOverdue && status.name === 'ACTIVE' ? 'error' : 'text.dark'}>{shortDate}</Typography>
        );
      },
    },
    {
      field: 'amountDue',
      headerName: t('base.labels.amountDue'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'paymentDate',
      headerName: t('base.labels.paymentDate'),
      filterable: false,
      minWidth: 120,
      flex: 0.3,
      renderCell: ({ row: { paymentDate } }) => {
        const shortDate = filters.date(paymentDate);
        return <Typography>{shortDate}</Typography>;
      },
    },
    {
      field: 'amountPaid',
      headerName: t('base.labels.amountPaid'),
      filterable: false,
      minWidth: 100,
      flex: 0.3,
    },
    {
      field: 'comment',
      headerName: t('base.labels.comment'),
      filterable: false,
      minWidth: 160,
      flex: 0.4,
    },
  ];

  return (
    <DataTableWrapper sx={{ height: isNested ? '100%' : 'calc(100% - 76px)', overflowY: 'auto' }}>
      <DataTable
        list={list}
        totalElements={totalElements}
        isLoading={isLoading}
        columns={columns}
        columnVisibilityModel={
          columnVisibilityModel ? columnVisibilityModel : { paymentDate: false, amountPaid: false }
        }
        totalPages={totalPages}
        setSearchParams={setSearchParams}
        pageQuery={pageQuery}
        pageSizeQuery={pageSizeQuery}
        searchQuery={searchQuery}
        sortByQuery={sortByQuery}
        sortDirectionQuery={sortDirectionQuery}
        hideFooter={hideFooter}
        managerQuery={managerQuery}
        periodQuery={periodQuery}
      />
    </DataTableWrapper>
  );
};

export default memo(InvoicesList);
